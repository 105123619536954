import { Menu, Dropdown } from 'antd';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { API_URLs, DEFAULT_AVATAR, ROUTES } from '../../constants/app';
import { logout } from "../../redux/actions/userAction";
const TEXT_SHOW = {
    SHAREHOLDER: 'Cổ đông',
    ADMIN: 'Quản trị viên'
}
const MyHeaderUser = ({ onClick }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logout());
        onClick(e);
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link className="dropdown-item" to={ROUTES.PROFILE}>
                    Tài khoản
                </Link>
            </Menu.Item>

            <Menu.Item key='menu-item-logout'>
                <a className="dropdown-item" onClick={(e) => handleLogout(e)} href="#">
                    Đăng xuất
                </a>
            </Menu.Item>
        </Menu>
    );
    return <>
    {(userInfo)?<p>Xin chào {`${TEXT_SHOW[userInfo.role]}`} {userInfo.name}</p>:<></>}
    
        <div className="ne-header-dropdown ne-header-user">
            
            <Dropdown overlay={menu} trigger={['click']} overlayClassName="ne-dropdown-header-overlay" placement="bottomRight">
                <a className="dropdown-toggle" onClick={e => e.preventDefault()}>
                    <img src={(userInfo && userInfo?.avatar !== DEFAULT_AVATAR)? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${userInfo.avatar}` : DEFAULT_AVATAR} alt="Avatar" />
                </a>
            </Dropdown>
        </div>
    </>
}
export default MyHeaderUser