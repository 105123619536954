import React from "react";
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

const MyHeaderNotification = ({ onClick }) => {
    const menu = (
        <Menu>
            <Menu.Item key='noti-1'>
                <Link className="dropdown-item" onClick={onClick} to="/">
                    Notification #1
                </Link>
            </Menu.Item>
            <Menu.Item key='noti-2'>
                <Link className="dropdown-item" onClick={onClick} to="/">
                    Notification #1
                </Link>
            </Menu.Item>
            <Menu.Item key='noti-3'>
                <Link className="dropdown-item" onClick={onClick} to="/">
                    Notification #1
                </Link>
            </Menu.Item>
        </Menu>
    );
    return <div className="ne-header-dropdown ne-header-notification">
        <Dropdown overlay={menu} trigger={['click']} overlayClassName="ne-dropdown-header-overlay" placement="bottomRight">
            <a id="dropdown-notification" className="dropdown-toggle fs-24" onClick={e => e.preventDefault()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-bell-outline" fill="currentColor"><g data-name="Layer 2"><g data-name="bell"><rect width="24" height="24" opacity="0"></rect><path d="M20.52 15.21l-1.8-1.81V8.94a6.86 6.86 0 0 0-5.82-6.88 6.74 6.74 0 0 0-7.62 6.67v4.67l-1.8 1.81A1.64 1.64 0 0 0 4.64 18H8v.34A3.84 3.84 0 0 0 12 22a3.84 3.84 0 0 0 4-3.66V18h3.36a1.64 1.64 0 0 0 1.16-2.79zM14 18.34A1.88 1.88 0 0 1 12 20a1.88 1.88 0 0 1-2-1.66V18h4zM5.51 16l1.18-1.18a2 2 0 0 0 .59-1.42V8.73A4.73 4.73 0 0 1 8.9 5.17 4.67 4.67 0 0 1 12.64 4a4.86 4.86 0 0 1 4.08 4.9v4.5a2 2 0 0 0 .58 1.42L18.49 16z"></path></g></g></svg>
                <span className="ne-total-badge">12</span>
            </a>
        </Dropdown>
    </div>
}
export default MyHeaderNotification