export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const DEFAULT_AVATAR = '/images/01.png';
export const DEFAULT_COVER = '/images/01.png';
export const DATE_TIME_FORMAT = 'h:mm:ss A \nDD/MM/YYYY';
export const ITEMS_PER_PAGE = 10;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const USER_ROLE = {
    SHAREHOLDER: 'SHAREHOLDER',
    ADMIN: 'ADMIN'
};
export const USER_GENDER = {
    MALE: 'MALE',
    FEMALE: 'FEMALE'
};
export const DEFAULT_IMAGE = '/images/No_Image_Available.jpg';

export const API_URLs = {
    LOGIN: '/api/v1/auth/login',
    LOG_OUT: '/api/v1/auth/logout',
    GET_USER_INFO: '/api/v1/auth/info',
    
    USER: '/api/v1/user',

    EVENT: '/api/v1/event',
    BIEUQUYET_CONTENT: '/api/v1/bieuquyet/content',
    BINHBAU_CONTENT: '/api/v1/binhbau/content',
    VOTE_TICKET: '/api/v1/ticket',
    UPLOAD: '/api/v1/upload',
    DOWNLOAD: '/api/v1/download',
    UPDATE_MY_ACC: '/api/v1/auth/me',
    UYQUYEN: '/api/v1/uyquyen',

}
export const ROUTES = {
    // Share
    LOGIN: '/login',
    RECOVER_PASSWORD: '/recover-password',
    PROFILE: '/profile',
    USER: '/user',

    // Admin
    EVENT: '/event',
    DETAIL_EVENT: '/event/:mode/:id?',
    MANAGE_DOCS: '/managerdocs',
    MANAGE_BIEUQUYET_INFO: '/manabieuquyet/:mode/:id?',
    MANAGE_BIEUQUYET: '/manabieuquyet',
    MANAGE_BINHBAU_INFO: '/manabinhbau/:mode/:id?',
    MANAGE_BINHBAU: '/manabinhbau',
    
    // SHAREHOLDER
    BIEUQUYET: '/bieuquyet',
    UYQUYEN: '/uyquyen',
    BINHBAU: '/binhbau',

    ACCESS_DENIED: '/403',
    PAGE_NOT_FOUND: '/404',
}
{/* <FontAwesomeIcon icon="far fa-box-ballot" /> */}
export const MENUS = [
    { route: ROUTES.USER, title: 'Cổ đông', icon: 'fas fa-user-shield', roles: USER_ROLE.ADMIN },
    { route: ROUTES.EVENT, title: 'Sự kiện', icon: 'fas fa-image' },
    // { route: ROUTES.MANAGE_DOCS, title: 'Quản lý tài liệu', icon: 'fas fa-file-invoice', roles: USER_ROLE.ADMIN },
    // { route: ROUTES.MANAGE_BIEUQUYET, title: 'Quản lý biểu quyết', icon: 'fas fa-poll', roles: USER_ROLE.ADMIN },
    // { route: ROUTES.MANAGE_BINHBAU, title: 'Quản lý bình bầu', icon: 'fas fa-poll-h', roles: USER_ROLE.ADMIN },
    
    // { route: ROUTES.HOME_PAGE, title: 'Trang chủ', icon: 'far fa-list-alt', roles: USER_ROLE.SHAREHOLDER },
    // { route: ROUTES.UYQUYEN, title: 'Ủy quyền', icon: 'fas fa-users', roles: USER_ROLE.SHAREHOLDER },
    // { route: ROUTES.BIEUQUYET, title: 'Biểu quyết', icon: 'fas fa-poll', roles: USER_ROLE.SHAREHOLDER },
    // { route: ROUTES.BINHBAU, title: 'Bình bầu', icon: 'fas fa-poll-h', roles: USER_ROLE.SHAREHOLDER },
   
    
    

    // { route: ROUTES.REPORTS, title: 'Reports', icon: 'far fa-chart-bar', roles: USER_ROLE.ADMIN },

    // { route: ROUTES.OVERVIEW, title: 'Dashboard', icon: 'fas fa-tachometer-alt', roles: USER_ROLE.USER },
    // { route: '', title: 'FACE SERVICES', icon: '', isGroup: true, roles: USER_ROLE.USER, type: USER_TYPE.FACE },
    // { route: ROUTES.FACES, title: 'Face Management', icon: 'fas fa-expand', roles: USER_ROLE.USER, type: USER_TYPE.FACE },
    // { route: '', title: 'VOICE SERVICES', icon: '', isGroup: true, roles: USER_ROLE.USER, type: USER_TYPE.VOICE },
    // { route: ROUTES.VOICES, title: 'Voice Management', icon: 'far fa-file-audio', roles: USER_ROLE.USER, type: USER_TYPE.VOICE },
    // { route: 'https://documenter.getpostman.com/view/4111892/UUy1fSsn#09b378d8-5506-4f8f-9b19-410008d49caf', title: 'API Documents', icon: 'far fa-save', external: true, roles: USER_ROLE.USER, type: USER_TYPE.FACE },
    // { route: 'https://documenter.getpostman.com/view/4078648/UV5ZCGsC', title: 'API Documents', icon: 'far fa-save', external: true, roles: USER_ROLE.USER, type: USER_TYPE.VOICE },

    // { route: ROUTES.VERIFIED_REQUESTS, title: 'Verified Requests', icon: 'far fa-clock', roles: USER_ROLE.USER },
    // { route: '', title: 'DEMOS', icon: '', isGroup: true, roles: USER_ROLE.USER },
    // { route: ROUTES.VOICE_MATCH_DEMO, title: 'Voice Match Demo', icon: 'fas fa-microphone-alt', roles: USER_ROLE.USER, type: USER_TYPE.VOICE },
    // { route: ROUTES.FACE_SEARCH_DEMO, title: 'Face Search Demo', icon: 'fas fa-search', roles: USER_ROLE.USER, type: USER_TYPE.FACE },
    // { route: ROUTES.FACE_COMPARE_DEMO, title: 'Face Compare Demo', icon: 'far fa-object-ungroup', roles: USER_ROLE.USER, type: USER_TYPE.FACE },
    // { route: ROUTES.REGISTER_FACE, title: 'Register Face', icon: 'fas fa-expand', roles: USER_ROLE.USER, type: USER_TYPE.FACE },

    // { route: '', title: 'ADMINISTRATION', icon: '', isGroup: true, roles: USER_ROLE.ADMIN },
    // { route: ROUTES.CUSTOMERS, title: 'Customers', icon: 'fas fa-users', roles: USER_ROLE.ADMIN },
    // { route: ROUTES.USERS, title: 'System Users', icon: 'fas fa-user-shield', roles: USER_ROLE.ADMIN },
    // { route: ROUTES.MODEL_MONITORING, title: 'Model Monitoring', icon: 'far fa-compass', roles: USER_ROLE.ADMIN },
    // { route: ROUTES.SYSTEM_CONFIGS, title: 'System Configs', icon: 'fas fa-cogs', roles: USER_ROLE.ADMIN },

    // { route: '', title: 'ACCOUNT', icon: '', isGroup: true },
    // { route: ROUTES.PROFILE, title: 'My Profile', icon: 'far fa-user' },
]