import BaseService from "./baseService";
import { API_URLs } from '../constants/app';

class VoteService extends BaseService {

    async get_info_vote(params){
        const res = await this.get(`${API_URLs.VOTE_TICKET}/info`, params);
        return res;
    }

    async uyquyen(params){
        const res = await this.post(`${API_URLs.UYQUYEN}`, params)
        return res;
    }

    async get_uyquyen(params){
        const res = await this.get(`${API_URLs.UYQUYEN}`, params)
        return res;
    }


    async get_uyquyen_list(params){
        const res = await this.get(`${API_URLs.UYQUYEN}/list`, params);
        return res;
    }

    async vote_ticket(id, params){
        const res = await this.put(`${API_URLs.VOTE_TICKET}/vote/${id}`, params)
        return res;
    }
    // async create(params) {
    //     const res = await this.post(API_URLs.BINHBAU_CONTENT, params);
    //     return res;
    // }

    // async get_list_binhbau(params) {
    //     const res = await this.get(API_URLs.BINHBAU_CONTENT, params);
    //     return res;
    // }
    
    // async get_binhbau(id){
    //     console.log(`${API_URLs.BINHBAU_CONTENT}/${id}`)
    //     const res = await this.get(`${API_URLs.BINHBAU_CONTENT}/${id}`);
    //     return res;
    // }

    // async update_bb(id, params){
    //     const res = await this.put(`${API_URLs.BINHBAU_CONTENT}/${id}`, params);
    //     return res;
    // }

    // async delete_bb(id) {
    //     const res = await this.delete(`${API_URLs.BINHBAU_CONTENT}/${id}`);
    //     return res;
    // }
    // async delete_event(id){
    //     const res = await this.delete(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async get_event_info(id){
    //     const res = await this.get(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async update_event(id, params){
    //     const res = await this.put(`${API_URLs.EVENT}/${id}`, params);
    //     return res;
    // }
}

export default VoteService;
