import NextPageHeader from "../../../components/MyPageHeader";
import {Card} from 'antd';
import './index.css';

const ManageDocument = () => {
    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title="Quản lý tài liệu"
            icon="fas fa-file-invoice"
            breadcrumb="Quản lý tài liệu"
        />

        <Card/>
    </div>
}
export default ManageDocument;