import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import { Card } from 'antd';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { USER_ROLE, ROUTES, API_URLs } from "../../../constants/app";
import { MESSAGES } from "../../../constants/message";
import { history } from "../../../router/history";
import { toast } from 'react-toastify';
import moment from 'moment-timezone'
import API from '../../../services/API';
import './index.css';
import fileDownload from 'js-file-download';
import { CornerUpLeft, Trash2 } from 'react-feather'

const ManageBieuQuyet = (props) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const currentUser = useSelector(state => state.user.userInfo);
    const [viewMode, setViewMode] = useState('');
    const [bq_content, setBQContent] = useState('');
    const [bq_title, setBQTitle] = useState('');
    const [bqType, setBQType] = useState('BIEUQUYET_CP');
    const [eventID, setEventID] = useState('');
    const [soCD, setSoCD] = useState(0);
    const [soCP, setSoCP] = useState(0);
    const [tileThamGia, setTiLeThamGia] = useState(0);
    const [tileThamGiaBQ, setTiLeThamGiaBQ] = useState(0);
    const [tileTanThanh, setTiLeTanThanh] = useState(0);
    const [tileKoTanThanh, setTiLeKoTanThanh] = useState(0);
    const [tileKoBQ, setTiLeKoBQ] = useState(0);

    const [bqExpire, setBQExpire] = useState(moment());
    const [bqExpireTime, setBQExpireTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('HH:mm'));
    const [bqExpireDate, setBQExpireDate] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'));

    const [bqStart, setBQStart] = useState(moment());
    const [bqStartTime, setBQStartTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('HH:mm'));
    const [bqStartDate, setBQStartDate] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'));

    const [attachment, setAttachment] = useState(null)
    const get_bq_info = async (id) => {
        try {
            const bq_info = await API.bieuquyet_content.get_bieuquyet(id);
            setBQContent(bq_info.content);
            setBQTitle(bq_info.title);
            setBQType(bq_info.type);
            setEventID(bq_info.event_id._id);

            const expired_time = moment.utc(bq_info.expired_at).tz('Asia/Ho_Chi_Minh')
            setBQExpire(expired_time);
            setBQExpireDate(expired_time.format('YYYY-MM-DD'))
            setBQExpireTime(expired_time.format('HH:mm'))
            setAttachment(bq_info.attachment)

            const started_time = moment.utc(bq_info.started_at).tz('Asia/Ho_Chi_Minh')
            setBQStart(started_time);
            setBQStartDate(started_time.format('YYYY-MM-DD'))
            setBQStartTime(started_time.format('HH:mm'))

            const result = bq_info.result
            setSoCD(result.so_cd)
            setSoCP(result.total_cophieu)
            const tile_thamGia_tmp = result.total * 100 / result.so_cd
            setTiLeThamGia((Number.isInteger(tile_thamGia_tmp) ? tile_thamGia_tmp.toFixed(0) : tile_thamGia_tmp.toFixed(2)))

            const tile_thamGiaBQ_tmp = result.count_tham_gia_bq * 100 / result.total
            setTiLeThamGiaBQ((Number.isInteger(tile_thamGiaBQ_tmp) ? tile_thamGiaBQ_tmp.toFixed(0) : tile_thamGiaBQ_tmp.toFixed(2)))

            const tileTanThanh_tmp = result.tanthanh_rate * 100
            setTiLeTanThanh((Number.isInteger(tileTanThanh_tmp) ? tileTanThanh_tmp.toFixed(0) : tileTanThanh_tmp.toFixed(2)))

            const tileKoTanThanh_tmp = result.kotanthanh_rate * 100
            setTiLeKoTanThanh((Number.isInteger(tileKoTanThanh_tmp) ? tileKoTanThanh_tmp.toFixed(0) : tileKoTanThanh_tmp.toFixed(2)))

            const tileKoBQ_tmp = result.khongbophieu_rate * 100
            setTiLeKoBQ((Number.isInteger(tileKoBQ_tmp) ? tileKoBQ_tmp.toFixed(0) : tileKoBQ_tmp.toFixed(2)))

        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    useEffect(() => {
        if (bqExpireDate && bqExpireTime) {
            setBQExpire(moment.tz(`${bqExpireDate} ${bqExpireTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Ho_Chi_Minh'))
        }
    }, [bqExpireDate, bqExpireTime])

    useEffect(() => {
        if (bqStartDate || bqStartTime) {
            console.log(bqStartDate, bqStartTime)
            setBQStart(moment.tz(`${bqStartDate} ${bqStartTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Ho_Chi_Minh'))
        }
    }, [bqStartDate, bqStartTime])

    useEffect(async () => {
        if (currentUser && currentUser.role !== USER_ROLE.ADMIN) {
            history.push(ROUTES.ACCESS_DENIED);
        }
        const mode = props?.match?.params?.mode;
        if (mode) {
            setViewMode(mode)
        }

        if (mode !== 'add-new') {
            console.log('Get info')
            await get_bq_info(props?.match?.params?.id);
        }
    }, [props, currentUser])


    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false || !currentUser) {
            return
        }

        let postData = {
            title: bq_title,
            content: bq_content,
            type: bqType,
            expired_at: bqExpire,
            started_at: bqStart,
            event_id: props?.match?.params?.id,
            attachment,
        }
        try {
            console.log('SUBBIT')

            // // toast.success(MESSAGES.UpdateSuccess);
            if (viewMode === 'add-new') {
                if (bqExpire - bqStart < 0) {
                    toast.error('Thời gian bắt đầu phải trước thời gian kết thúc');
                    return;
                }
                await API.bieuquyet_content.create(postData);
                toast.success('Tạo biểu quyết thành công');
                history.push(`${ROUTES.EVENT}/view/${props?.match?.params?.id}`)
            }

            if (viewMode === 'view') {
                history.push(`${ROUTES.MANAGE_BIEUQUYET}/edit/${props?.match?.params?.id}`)
            }

            if (viewMode === 'edit') {
                if (bqExpire - bqStart < 0) {
                    toast.error('Thời gian bắt đầu phải trước thời gian kết thúc');
                    return;
                }
                await API.bieuquyet_content.update_bq(props?.match?.params?.id, postData);
                toast.success('Cập biểu quyết thành công');
                history.push(`${ROUTES.EVENT}/view/${eventID}`)
            }

            // dispatch(getUserInfo());
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };


    const getExportData = async () => {
        const res = await API.bieuquyet_content.export_bq(props?.match?.params?.id)
        console.log(res)
        var a = window.document.createElement('a');
        var universalBOM = "\uFEFF";
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + res));
        a.setAttribute('download', `kq_bieu_quyet_${bq_title}.csv`);
        window.document.body.appendChild(a);
        a.click();
        // fileDownload(res, `kq_bieu_quyet_${bq_title}.csv`)
    }
    const onExport = async () => {
        toast.info("Đang export dữ liệu, vui lòng đợi.");
        try {
            const res = await API.bieuquyet_content.export_bq(props?.match?.params?.id)
            const blob = new Blob([res], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            a.href = url;
            a.download = `kq_bieu_quyet_${bq_title}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

            // const filename = res.file_url.split("/").pop();
            // await downloadBlob(res.file_url, filename);
            toast.success("Export dữ liệu thành công.");
        } catch (error) {
            toast.error("Export lỗi: " + error.message);
        }
    };

    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title={(viewMode === 'add-new') ? 'Thêm mới biểu quyết' : 'Chi tiết biểu quyết'}
            icon="fas fa-poll"
            breadcrumb="Quản lý biểu quyết"
        />

        <Card>
            <Row md='1'>
                <a className="mb-4" onClick={() => history.push(`/event/view/${eventID}`)}> <CornerUpLeft /> Quay về sự kiện</a>
                <Card className="mb-3">
                    <h5 className="mb-3">Thông tin chung</h5>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlName">
                                    <Form.Label>Tiêu đề biểu quyết <span>*</span></Form.Label>
                                    <Form.Control required value={bq_title} placeholder='Tiêu đề biểu quyết' onChange={(e) => setBQTitle(e.target.value)} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Tiêu đề biểu quyết không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlName">
                                    <Form.Label>Kiểu biểu quyết <span>*</span></Form.Label>
                                    <Form.Check type='radio' name="group1" id='BIEUQUYET_BD' checked={bqType === 'BIEUQUYET_BD'} onChange={() => { setBQType('BIEUQUYET_BD') }} label='Biểu quyết bình đẳng' disabled={viewMode === 'view'} />
                                    <Form.Check type='radio' name="group1" id='BIEUQUYET_CP' checked={bqType === 'BIEUQUYET_CP'} onChange={() => { setBQType('BIEUQUYET_CP') }} label='Biểu quyết theo cổ phần' disabled={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Kiểu biểu quyết không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlCType">
                                    <Form.Label>Nội dung biểu quyết <span>*</span></Form.Label>
                                    <Form.Control required value={bq_content} placeholder='Nội dung biểu quyết' onChange={(e) => { setBQContent(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Nội dung biểu quyết không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlStartTime">
                                    <Form.Label>Thời gian bắt đầu <span>*</span></Form.Label>
                                    <Form.Control type='date' required value={bqStartDate} onChange={(e) => { console.log(e.target.value); setBQStartDate(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control type='time' required value={bqStartTime} onChange={(e) => { console.log(e.target.value); setBQStartTime(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Vui lòng chọn thời điểm bắt đầu.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlStatus">
                                    <Form.Label>Thời gian kết thúc <span>*</span></Form.Label>
                                    <Form.Control type='date' required value={bqExpireDate} onChange={(e) => { setBQExpireDate(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control type='time' required value={bqExpireTime} onChange={(e) => { setBQExpireTime(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Vui lòng chọn thời điểm kết thúc.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlAtt">
                                    <Form.Label>Tệp đính kèm {attachment && viewMode === "edit" && <a onClick={() => setAttachment('')}><Trash2 className='ms-1' size={15} /></a>}</Form.Label>
                                    <div>
                                        {attachment && <a href={`${window.API_BASE_URL}${API_URLs.DOWNLOAD}/${attachment}`} download={attachment}>{attachment}</a>}

                                    </div>
                                    {viewMode !== "view" && <input type='file' onChange={async (e) => {
                                        const file = e.target.files[0]
                                        const formData = new FormData()
                                        formData.append("file", file)
                                        API.upload.uploadAttachment(formData).then((res) => {
                                            console.log(res.file_path)
                                            setAttachment(res.file_path);
                                        })
                                    }} />}
                                </Form.Group>
                            </Col>
                        </Row>

                        {(currentUser && currentUser.role === USER_ROLE.ADMIN) ? <div className="mt-3">
                            <Button type="submit" variant="primary">{(viewMode === 'add-new') ? 'Thêm mới' : (viewMode === 'view') ? 'Sửa' : 'Lưu thay đổi'}</Button>
                        </div> : <div />
                        }

                    </Form>

                </Card>
            </Row>

            {(viewMode === 'view') ? <Row md='1'>
                <Card className="mb-3">
                    <h5 className="mb-3">Kết quả biểu quyết</h5>
                    <Row>
                        <Col md="4">

                            <Button
                                color="primary"
                                onClick={() => { onExport() }}
                                size={'small'}
                                variant="primary"
                            >
                                Export
                            </Button>
                        </Col>
                    </Row>

                    <Row >
                        <Col md="3">
                            <Card title='Tổng cổ đông'>
                                <h2>{soCD}</h2>
                            </Card>
                        </Col>
                        {(bqType === 'BIEUQUYET_BD') ? <div /> :
                            <Col md="3">
                                <Card title='Tổng số cổ phiếu'>
                                    <h2>{soCP}</h2>
                                </Card>
                            </Col>
                        }
                        <Col md="3">
                            <Card title='Tỷ lệ tham gia'>
                                <h2>{tileThamGia}%</h2>
                            </Card>
                        </Col>
                        {/* <Col md="3">
                            <Card title='Tỷ lệ tham gia bỏ phiếu'>
                                <h2>{tileThamGiaBQ}%</h2>
                            </Card>
                        </Col> */}
                    </Row>
                    <br />
                    <Row >
                        <Col md="3">
                            <Card title='Tỷ lệ tán thành'>
                                <h2>{tileTanThanh}%</h2>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card title='Tỷ lệ không tán thành'>
                                <h2>{tileKoTanThanh}%</h2>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card title='Tỷ lệ phiếu trống'>
                                <h2>{tileKoBQ}%</h2>
                            </Card>
                        </Col>

                    </Row>
                </Card>
            </Row> : <div />}
        </Card>
    </div>
}
export default ManageBieuQuyet;