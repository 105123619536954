import BaseService from "./baseService";
import { API_URLs } from '../constants/app';

class EventService extends BaseService {
    async create(params) {
        const res = await this.post(API_URLs.EVENT, params);
        return res;
    }

    async get_event(params) {
        const res = await this.get(API_URLs.EVENT, params);
        return res;
    }

    async delete_event(id){
        const res = await this.delete(`${API_URLs.EVENT}/${id}`);
        return res;
    }

    async get_event_info(id){
        const res = await this.get(`${API_URLs.EVENT}/${id}`);
        return res;
    }

    async update_event(id, params){
        const res = await this.put(`${API_URLs.EVENT}/${id}`, params);
        return res;
    }
}

export default EventService;
