import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import { Link } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { Card, Table, Button, Tooltip } from 'antd';
import './index.css';
import { toast } from "react-toastify";
import { getUserInfo } from "../../../redux/actions/userAction";
import { DEFAULT_COVER, ITEMS_PER_PAGE, ROUTES, DATE_FORMAT, USER_ROLE, API_URLs } from "../../../constants/app";
import { MESSAGES } from '../../../constants/message';
import { history } from "../../../router/history";
import moment from 'moment-timezone';
import swal from "sweetalert";
import API from '../../../services/API';

const ManageEvent = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.userInfo);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [dataSource, setDataSource] = useState([]);


    let columns_tmp = [
        {
            title: 'Ảnh sự kiện',
            dataIndex: 'image',
            width: '10%',
            render: text => {
                return <div className="td-image ">
                    <img src={(text && text!== DEFAULT_COVER)? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${text}` : DEFAULT_COVER} />
                    </div>
            }
        },
        {
            title: 'Sự kiện',
            dataIndex: 'title',
            width: '40%',
            render: (text, item) => {
                const link_to = `${ROUTES.EVENT}/view/${item._id}`
                return <Link className="ant-bold" to={link_to}>
                    <b>{text}</b>
                </Link>
            }
        },
        {
            title: 'Ngày tổ chức',
            width: '10%',
            dataIndex: 'event_time',
            className: 'text-center',
            render: text => {
                return <span>{moment.utc(text).tz('Asia/Ho_Chi_Minh').format(DATE_FORMAT)}</span>;
            },
        },
        {
            title: 'Tùy chọn',
            dataIndex: '_id',
            width: '10%',
            className: 'text-center',
            render: (text, item) => {
                return <div className="text-center text-nowrap">
                    <Tooltip title="View">
                        <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={`${ROUTES.EVENT}/view/${item._id}`}>
                            <i className="far fa-eye"></i>
                        </Link>
                    </Tooltip>

                </div>
            }
        },
    ];
    const [columns, setColumns] = useState(columns_tmp)
    const get_event = async (pageIndex, keyword) => {
        try {
            setLoading(true);
            const params = {
                page: pageIndex,
                pagesize: ITEMS_PER_PAGE,
                q: keyword
            };
            const event = await API.event.get_event(params);
            console.log(event);
            setDataSource(event.data);
            setTotalItems(event.total);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            setLoading(false);
        }
    }
    useEffect(() => {
        if (currentUser && currentUser.role === USER_ROLE.ADMIN) {
            columns_tmp[3].render = (text, item) => {

                return <div className="text-center text-nowrap">
                    <Tooltip title="Edit">
                        <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={`${ROUTES.EVENT}/edit/${item._id}`}>
                            <i className="far fa-edit"></i>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button shape="round" type="danger" className="ml-2" onClick={() => handleDelete(item._id)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </Tooltip>
                </div>
            }
            setColumns(columns_tmp)
        }
    }, [currentUser])
    useEffect(() => {
        dispatch(getUserInfo());
        get_event(pageIndex, keyword);
    }, []);

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('On table change')
        setPageIndex(pagination.current);
        get_event(pagination.current, keyword);
        // loadUsersData(pagination.current, keyword);
    }

    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        console.log('Keyword change')
        get_event(pageIndex, e.target.value);
    }

    const handleAddNewClick = () => {
        history.push(`${ROUTES.EVENT}/add-new`);
    }

    const handleDelete = (id) => {
        if (!id) return;
        swal({
            text: "Are you sure you want to delete this item?",
            buttons: {
                cancel: {
                    text: "No",
                    className: "btn btn-dark px-3",
                    closeModal: true,
                    visible: true
                },
                confirm: {
                    text: "Yes",
                    className: "btn btn-danger px-3"
                }
            },
        }).then(async (willDelete) => {
            if (willDelete) {
                console.log('DELETE')
                try {
                    await API.event.delete_event(id)
                    toast.success(MESSAGES.DeleteSuccess);
                    await get_event(pageIndex, keyword);
                } catch (error) {
                    toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
                }
            }
        });
    }

    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title="Quản lý sự kiện"
            icon="fas fa-image"
            breadcrumb="Quản lý sự kiện"
        />

        <Card>
            <Row className="mb-3">
                {(currentUser && currentUser.role === USER_ROLE.SHAREHOLDER)?<div/>:
                <Col md="6">
                    <Button type="primary" onClick={handleAddNewClick}>
                        <i className="mr-1 fas fa-plus"></i>
                        <span>Thêm mới</span>
                    </Button>
                </Col>}
                <Col md="6" className="mt-3 mt-md-0">
                    <input type="search" className="form-control ml-md-auto w-auto" placeholder="Tìm kiếm ..." value={keyword} onChange={handleKeywordChange} />
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={dataSource}
                onChange={onChange}
                loading={loading}
                pagination={{ pageSize: ITEMS_PER_PAGE, total: totalItems }}
                rowKey={record => record._id} />
        </Card>
    </div>
}
export default ManageEvent;