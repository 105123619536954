import axios from 'axios';
import { ACCESS_TOKEN, ROUTES } from '../constants/app';
import { toast } from 'react-toastify';
import { history } from '../router/history';
export default class BaseService {
    configApi(headers, baseURL) {
        const globalAxios = axios.create({
            //   baseURL: process.env.REACT_APP_REST_URL,
            baseURL: baseURL ? baseURL : window.API_BASE_URL
        });
        globalAxios.interceptors.request.use(function(config) {
            if (headers) {
                config.headers = headers;
            }
            config.headers.authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
            return config;
        });

        
        globalAxios.interceptors.response.use(
            response => response,
            error=> {
              const response = error.response
              if(response.status === 401){
                toast.dismiss();
                localStorage.removeItem(ACCESS_TOKEN);
                setTimeout(()=>{
                    history.push(ROUTES.LOGIN);
                },500)
              }
              throw error;
            });


        return globalAxios;
    }

    async post(url, data, headers) {
        const api = this.configApi(headers);
        const res = await api.post(url, data);

        if (res && res.data) {
            return res.data;
        }

        return res;
    }

    async get(url, params) {
        const res = await this.configApi().get(url, { params: params });
        if (res && res.data) {
            return res.data;
        }

        return res;
    }

    async export(url, params) {
        const res = await this.configApi().get(url, { params, responseType: 'blob' });
        if (res && res.data) {
            return res.data;
        }

        return res;
        
    }

    async put(url, data) {
        const res = await this.configApi().put(url, data);

        if (res && res.data) {
            return res.data;
        }

        return res;
    }

    async delete(url, data) {
        const res = await this.configApi().delete(url, data);

        if (res && res.data) {
            return res.data;
        }

        return res;
    }
}