import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Card, Radio, Checkbox, Image } from 'antd';
import { toast } from "react-toastify";
import './index.css';
import moment from 'moment-timezone'
import { getUserInfo } from "../../../redux/actions/userAction";
import API from "../../../services/API";
import { MESSAGES } from "../../../constants/message";
import { DEFAULT_COVER, API_URLs } from "../../../constants/app";
import { CornerUpLeft } from "react-feather";
import { history } from "../../../router/history";

const BieuQuyet = (props) => {
    const dispatch = useDispatch();
    const [content, setContent] = useState({});
    const currentUser = useSelector(state => state.user.userInfo);
    const [validated, setValidated] = useState(false);
    const [validUyQuyen, setValidUyQuyen] = useState(true);
    const [uyquyen_type, setUyQuyenType] = useState('BQCN');
    const [bq_uyquyen_type, setBQUyQuyenType] = useState('BQUQ_SAME');
    const [kq_bieuquyet, setKQBieuQuyet] = useState('');
    const [kq_bieuquyet_uq, setKQBieuQuyetUQ] = useState('');
    const [kq_bieuquyet_cn, setKQBieuQuyetCN] = useState('');
    const [ds_ng_uyquyen, setDSUyQuyen] = useState([]);
    const [ng_uyquyen, setNgUyQuyen] = useState([]);
    const [ticket_id, setTicketID] = useState('');
    const [isBQCN, setIsBQCN] = useState(false);
    const [isBQUQ, setIsBQUQ] = useState(false);
    const [expired_time, setExpiredTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh'));
    const [notstart, setNotStart] = useState(false);
    const [started_time, setStardTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh'));
    const BIEUQUYET_TYPE = { BIEUQUYET_BD: 'Biểu quyết bình đẳng', BIEUQUYET_CP: 'Biểu quyết theo cổ phiếu sở hữu' }
    const BQ_RES_TEXT = {TANTHANH: 'Tán thành', KHONGTANTHANH: 'Không tán thành', KHONGBIEUQUYET: 'Không biểu quyết'}
    const [eventID, setEventID] = useState('');
    const get_bieu_quyet_info = async (content_id, user_id) => {
        try {
            const params = {
                codong_id: user_id,
                content_id: content_id,
                content_type: 'BIEUQUYET'
            }
            const res = await API.vote_ticket.get_info_vote(params)
            if (res.response){
                const response_json = JSON.parse(res.response)
                setKQBieuQuyetCN(response_json.BQ_RES)
                setKQBieuQuyet(response_json.BQ_RES)
                setIsBQCN(true);
            }
            setTicketID(res._id);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    const get_uyquyen_list = async (event_id) => {
        try {
            
            const res = await API.vote_ticket.get_uyquyen_list({event_id})
            let ds_uyquyen = []
            for (let r of res){
                let label = r.name
                const params = {
                    codong_id: r._id,
                    content_id: props?.match?.params?.id,
                    content_type: 'BIEUQUYET'
                }
                const res_vote = await API.vote_ticket.get_info_vote(params)
                if (res_vote.response){
                    const response_json = JSON.parse(res_vote.response)
                    console.log(response_json)
                    setKQBieuQuyetUQ(response_json.BQ_RES)
                    setKQBieuQuyet(response_json.BQ_RES)
                    setIsBQUQ(true);
                }
                ds_uyquyen.push({
                    label: label,
                    value: res_vote._id
                })
            }
            setDSUyQuyen(ds_uyquyen);
        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    const bieuquyet_options = [
        {
            label: 'Biểu quyết cá nhân',
            value: 'BQCN'
        },
        {
            label: 'Biểu quyết ủy quyền',
            value: 'BQUQ'
        },

    ]

    const bieuquyet_uyquyen_options = [
        {
            label: 'Biểu quyết cùng ý kiến',
            value: 'BQUQ_SAME'
        },
        // {
        //     label: 'Biểu quyết không cùng ý kiến',
        //     value: 'BQUQ_DIFF'
        // },

    ]
    const kq_bieuquyet_options = [
        {
            label: 'Tán thành',
            value: 'TANTHANH'
        },
        {
            label: 'Không tán thành',
            value: 'KHONGTANTHANH'
        },
        {
            label: 'Không biểu quyết',
            value: 'KHONGBIEUQUYET'
        },
    ]

    useEffect(() => {
        dispatch(getUserInfo());
    }, [])
    useEffect(async () => {
        console.log(props?.match?.params?.id)
        if (props?.match?.params?.id && currentUser) {
            const bq_content = await API.bieuquyet_content.get_bieuquyet(props?.match?.params?.id)
            setExpiredTime(moment.utc(bq_content.expired_at).tz('Asia/Ho_Chi_Minh'))

            setStardTime(moment.utc(bq_content.started_at).tz('Asia/Ho_Chi_Minh'))
            if (moment.utc(bq_content.started_at).tz('Asia/Ho_Chi_Minh') - moment.utc().tz('Asia/Ho_Chi_Minh') > 0){
                setNotStart(true)
            }
            console.log(bq_content)
            setContent({
                id: bq_content._id,
                title: bq_content.title,
                content: bq_content.content,
                type: bq_content.type,
                event: bq_content.event_id.title,
                event_id: bq_content.event_id._id,
                image: bq_content.event_id.image,
                attachment: bq_content.attachment
            })
            get_bieu_quyet_info(bq_content._id, currentUser._id);
            get_uyquyen_list(bq_content.event_id._id)
            setEventID(bq_content.event_id._id)
        }

    }, [props, currentUser])

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }

        if (expired_time - moment.utc().tz('Asia/Ho_Chi_Minh') < 0){
            toast.error('Đã kết thúc biểu quyết')
            return
        }

        if (uyquyen_type === 'BQCN'){
            if (!kq_bieuquyet){
                toast.error('Vui lòng biểu quyết')
                return
            }
            try{
                const params = {
                    response: JSON.stringify({BQ_RES: kq_bieuquyet})
                }
                await API.vote_ticket.vote_ticket(ticket_id, params)
                get_bieu_quyet_info(content.id, currentUser._id);
                get_uyquyen_list(content.event_id)
                toast.success('Biểu quyết cá nhân thành công')
            }
            catch(error){
                toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            }
            
        }
        if (uyquyen_type === 'BQUQ'){
            if (bq_uyquyen_type === 'BQUQ_SAME'){
                for (let ng_uyquyen_ of ds_ng_uyquyen){
                    try{
                        const params = {
                            response: JSON.stringify({BQ_RES: kq_bieuquyet_uq})
                        }
                        console.log(params, ng_uyquyen_)
                        await API.vote_ticket.vote_ticket(ng_uyquyen_.value, params)
                        get_bieu_quyet_info(content.id, currentUser._id);
                        get_uyquyen_list(content.event_id)
                        toast.success('Biểu quyết cá nhân thành công')
                    }
                    catch(error){
                        toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
                    }
                }
                
            }
            // if (bq_uyquyen_type === 'BQUQ_DIFF'){
            //     for (let ng_uyquyen_ of ds_ng_uyquyen){
            //         try{
            //             const params = {
            //                 response: JSON.stringify({BQ_RES: kq_bieuquyet})
            //             }
            //             await API.vote_ticket.vote_ticket(ng_uyquyen_.value, params)
            //             get_bieu_quyet_info(content.id, currentUser._id);
            //             get_uyquyen_list()
            //             toast.success('Biểu quyết cá nhân thành công')
            //         }
            //         catch(error){
            //             toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            //         }
            //     }

            //     for (let ng_uyquyen_ of ng_uyquyen){
            //         try{
            //             const params = {
            //                 response: JSON.stringify({BQ_RES: kq_bieuquyet})
            //             }
            //             await API.vote_ticket.vote_ticket(ng_uyquyen_, params)
            //             get_bieu_quyet_info(content.id, currentUser._id);
            //             get_uyquyen_list()
            //             toast.success('Biểu quyết cá nhân thành công')
            //         }
            //         catch(error){
            //             toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            //         }
            //     }
                
            // }
        }
        
    }

    // const onChangeUyquyen = (e) => {
    //     setValidUyQuyen(e)
    //     get_bieu_quyet_info(content.id, currentUser._id);
    // }
    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title="Biểu quyết"
            icon="fas fa-poll"
            breadcrumb="Biểu quyết"
        />
        <Card>
            <a className="mb-4" onClick={() => history.push(`/event/view/${eventID}`)}> <CornerUpLeft /> Quay về sự kiện</a>
        </Card>
        <Card>
            <div className="profile-img-edit position-relative">
                <Image
                    style={{ width: '100%' }}
                    className="profile-pic rounded w-100"
                    alt="profile-pic"
                    src={(content.image && content.image !== DEFAULT_COVER)?`${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${content.image}`:DEFAULT_COVER}
                    preview={false}
                    fallback={DEFAULT_COVER}
                />
                
            </div>
        </Card>

        <Card className="mb-3">
            <h4 className="mb-3">Sự kiện {content.event}</h4>
            <h5 className="mb-3">{content.title}</h5>
            <p className="mb-3">Tệp đính kèm</p>
            {content.attachment && <a href={`${window.API_BASE_URL}${API_URLs.DOWNLOAD}/${content.attachment}`} target="_blank" download={content.attachment}>{content.attachment}</a>}
            <p className="mb-3">{BIEUQUYET_TYPE[content.type]}</p>
            <p className="mb-3">{content.content}</p>
            <b className="mb-3 bold" style={{'color': 'red'}}>{`* Thời gian biểu quyết từ ${started_time.format('HH:mm DD/MM/YYYY')} đến ${expired_time.format('HH:mm DD/MM/YYYY')}`}</b>
            
            {(expired_time - moment.utc().tz('Asia/Ho_Chi_Minh') < 0)?<p className="mb-3">Đã kết thúc biểu quyết</p>:

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="justify-content-between">
                    <Radio.Group options={bieuquyet_options} onChange={(e) => { 
                        setUyQuyenType(e.target.value) 
                        if (e.target.value === 'BQCN'){
                            setKQBieuQuyet(kq_bieuquyet_cn)
                        }

                        if (e.target.value === 'BQUQ'){
                            setKQBieuQuyet(kq_bieuquyet_uq)
                        }
                        
                    }} value={uyquyen_type} />
                </Row>
                <br />
                {(notstart)?<p className="mb-3">Chưa bắt đầu biểu quyết</p>:<></>}
                {(isBQCN && uyquyen_type === 'BQCN')? <p className="mb-3">Đã biểu quyết cá nhân</p>:<div/>}
                {uyquyen_type === 'BQUQ' ?
                    <div>
                        <Row className="justify-content-between">
                            <Radio.Group options={bieuquyet_uyquyen_options} onChange={(e) => { setBQUyQuyenType(e.target.value) }} value={bq_uyquyen_type} />
                        </Row>
                        
                        <br />
                        <Card className="justify-content-between">
                            <h6 className="mb-3">Danh sách ủy quyền</h6>
                            <Checkbox.Group options={ds_ng_uyquyen} disabled={bq_uyquyen_type === 'BQUQ_SAME'} onChange={(e) => { setNgUyQuyen(e) }} value={ng_uyquyen} />
                        </Card>
                        <br />
                    </div> :
                    <div />
                }

                <Row className="justify-content-between">
                    <Radio.Group disabled={(uyquyen_type === 'BQUQ' && isBQUQ) || (uyquyen_type === 'BQCN' && isBQCN) || notstart} options={kq_bieuquyet_options} onChange={(e) => { 
                        if (uyquyen_type === 'BQCN'){
                            setKQBieuQuyetCN(e.target.value)
                        }
                        if (uyquyen_type === 'BQUQ'){
                            setKQBieuQuyetUQ(e.target.value)
                        }
                        setKQBieuQuyet(e.target.value)
                     }} value={kq_bieuquyet} />
                </Row>

                <div className="mt-3">
                    <Button disabled={(uyquyen_type === 'BQUQ' && isBQUQ) || (uyquyen_type === 'BQCN' && isBQCN) || notstart} type="submit" variant="primary">Biểu quyết</Button>
                </div>

            </Form>}
        </Card>
    </div>
}
export default BieuQuyet;