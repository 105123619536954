import React from "react";
import './index.css';
import MySidebarMenu from "./sidebar-menu";
import MySidebarToggle from "../MyHeader/header-sidebar-toggle";
import MyHeaderLogo from "../MyHeader/header-logo";

const MySidebar = ({ collapsed, onSidebarItemClick, onSidebarToggleClick }) => {

    return <div id="sidebar" className={`ne-sidebar ${collapsed ? 'ne-sidebar-collapsed' : 'ne-sidebar-expanded'}`}>
        <div className="ne-sidebar-wrap">
            <div className="d-flex align-items-center justify-content-between px-3 mb-4">
                <MyHeaderLogo />
                <MySidebarToggle onClick={(e) => onSidebarToggleClick(e)} />
            </div>
            <MySidebarMenu collapsed={collapsed} onSidebarItemClick={onSidebarItemClick} />
        </div>
    </div>
}
export default MySidebar