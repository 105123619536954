import React, { useEffect, useState } from "react";
import MyPageHeader from "../../../components/MyPageHeader";
import { Table, Button, Card, Tooltip, Image, Input, Modal } from 'antd';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { DEFAULT_AVATAR, ITEMS_PER_PAGE, ROUTES, API_URLs } from "../../../constants/app";
import { history } from "../../../router/history";
import swal from "sweetalert";
import API from "../../../services/API";
import { toast } from "react-toastify";
import { Row, Col } from 'react-bootstrap';
import { MESSAGES } from "../../../constants/message";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ROLE_TEXT = {
    ADMIN: 'QUẢN TRỊ',
    SHAREHOLDER: 'CỔ ĐÔNG'
}
const GENDER_TEXT = {
    MALE: 'Nam',
    FEMALE: 'Nữ'
}

let toast_id = null;

const UsersPage = () => {
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [importing, setImporting] = useState(false);

    useEffect(() => {
        loadUsersData(pageIndex, keyword);
    }, [])

    const loadUsersData = async (pageIndex, keyword) => {
        setLoading(true);
        try {
            const res = await API.user.search({
                page: pageIndex,
                pagesize: ITEMS_PER_PAGE,
                q: keyword
            });
            const transformData = res?.data?.map((item) => {
                return {
                    ...item,
                }
            }) || [];
            setTotalItems(res?.total || 0);
            setDataSource(transformData);
            setLoading(false);
        } catch (err) {
            setTotalItems(0);
            setDataSource([]);
            setLoading(false);
        }
    }
    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            width: 60,
            render: text => {
                return <div className="td-image">
                    <img src={(text && text !== DEFAULT_AVATAR) ? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${text}` : DEFAULT_AVATAR} />
                </div>
            }
        },
        {
            title: 'Họ và tên',
            dataIndex: 'name'
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            render: text => <div className="fw-500">{GENDER_TEXT[text]}</div>
        },
        {
            title: 'CMND/CCCD',
            dataIndex: 'cccd'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone'
        },
        {
            title: 'Quyền',
            dataIndex: 'role',
            render: text => <b className="fw-500">{ROLE_TEXT[text]}</b>
        },
        {
            title: '',
            dataIndex: '_id',
            width: 150,
            className: 'text-center',
            render: (text, item) => {
                return <div className="text-center text-nowrap">
                    <Tooltip title="Edit">
                        <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={`${ROUTES.USER}/edit/${item._id}`}>
                            <i className="far fa-edit"></i>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button shape="round" type="danger" className="ml-2" onClick={() => handleDelete(item._id)}>
                            <i className="far fa-trash-alt"></i>
                        </Button>
                    </Tooltip>
                </div>
            }
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        setPageIndex(pagination.current);
        loadUsersData(pagination.current, keyword);
    }

    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        loadUsersData(pageIndex, e.target.value);
    }

    const handleAddNewClick = () => {
        history.push(`${ROUTES.USER}/add-new`);
    }
    const handleImportClick = () => {
        history.push(`${ROUTES.USER}/import`);
    }
    const handleDelete = (id) => {
        if (!id) return;
        swal({
            text: "Are you sure you want to delete this item?",
            buttons: {
                cancel: {
                    text: "No",
                    className: "btn btn-dark px-3",
                    closeModal: true,
                    visible: true
                },
                confirm: {
                    text: "Yes",
                    className: "btn btn-danger px-3"
                }
            },
        }).then((willDelete) => {
            if (willDelete) {
                deleteUser(id);
            }
        });
    }

    // Call api to remove item
    const deleteUser = async (id) => {
        const res = await API.user.deleteById(id);
        toast.success(MESSAGES.RemoveSuccess);
        loadUsersData(pageIndex, keyword);
    }

    const handleFileUploadChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            console.log(formData)
            try {
                toast_id = toast.info("Importing", {autoClose: false})
                const res = await API.user.import_user(formData);
                toast.dismiss(toast_id)
                toast.success(`Import thành công ${res.success}: Lỗi: ${res.failed}`)

                await loadUsersData(pageIndex, keyword);
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const fileName = "result"
                const ws = XLSX.utils.json_to_sheet(res['data']);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, fileName + fileExtension);

            } catch (error) {
                toast.dismiss(toast_id)
                toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            }
            // dispatch(importCameras(formData));
        }
    }

    const handleSelectFile = () => {
        $('#fFile').val('');
        $('#fFile').trigger('click');
    }


    return <div className="ne-page-body ne-page-users">
        <MyPageHeader
            title="Người dùng"
            icon="fas fa-user-shield"
            breadcrumb="Người dùng"
        />
        <Card>
            <Row className="mb-4">
                <Col md="2">
                    <Button type="primary" onClick={handleAddNewClick}>
                        <i className="mr-1 fas fa-plus"></i>
                        <span>Thêm mới</span>
                    </Button>

                </Col>
                <Col md="3">
                    <a className="ant-btn ant-btn-dark" href="/templates/import_user.xlsx" target="_blank">
                        <i className="fas fa-download mr-2"></i>
                        <span>Download Template</span>
                    </a>
                </Col>
                <Col md="3">
                    <Button onClick={handleSelectFile} type="primary" className="mr-2">
                        <i className="fas fa-upload mr-2"></i>
                        <span>Import</span>
                    </Button>
                </Col>
                <Col md="2" className="mt-3 mt-md-0">
                    <input type="search" className="form-control ml-md-auto w-auto" placeholder="Tìm kiếm ..." value={keyword} onChange={handleKeywordChange} />
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={dataSource}
                onChange={onChange}
                loading={loading}
                pagination={{ pageSize: ITEMS_PER_PAGE, total: totalItems }}
                rowKey={record => record._id} />
        </Card>
        <Input id="fFile" type="file" multiple={false} className="d-none" onChange={handleFileUploadChange} accept=".xlsx" />
        
    </div>
}

export default UsersPage;