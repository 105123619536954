import React, { useEffect } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { history } from './router/history';
import { setThemeByMode } from './helpers/common';
import { AuthRoute } from './layout/routes/AuthRoute';
import { AppRoute } from './layout/routes/AppRoute';
import { ROUTES } from './constants/app';
import $ from 'jquery';
import LoginPage from './auth/Login';
import RecoverPasswordPage from './auth/RecoverPassword';
import PageNotFound from './pages/Shared/PageNotFound';
import AccessDeniedPage from './pages/Shared/AccessDenied';
import ProfilePage from './pages/Shared/Profile';
import UsersPage from './pages/Admin/Users';
import UserForm from './pages/Admin/Users/components/form'
import ManageDocument from './pages/Admin/ManageDocument';
import ManageBieuQuyet from './pages/Admin/BieuQuyet';
import ManageBinhBau from './pages/Admin/BinhBau';
import UyQuyen from './pages/ShareHolder/UyQuyen';
import BieuQuyet from './pages/ShareHolder/BieuQuyet';
import BinhBau from './pages/ShareHolder/BinhBau';
import ManageEvent from './pages/Shared/ManageEvent';
import EventDetail from './pages/Shared/ManageEvent/components/event_detail';
import PageForward from './pages/Shared/ForwardPage';
function App() {
  useEffect(() => {
    const currentTheme = localStorage.getItem('WEB_THEME');
    if (currentTheme) {
      setThemeByMode(currentTheme);
    }

    $(window).on('load', function () {
      setTimeout(() => {
        $(".preloader").delay(500).fadeOut("slow");
      }, 500);
    })
  }, [])

  return (
    <Router history={history}>
      <Switch>
        {/* For Manager role */}
        <AppRoute exact path='/' component={PageForward} />
        {/* <AppRoute exact path='/' component={ProfilePage} /> */}

        {/* Share */}
        <AuthRoute exact path={ROUTES.LOGIN} component={LoginPage} />
        <AuthRoute exact path={ROUTES.RECOVER_PASSWORD} component={RecoverPasswordPage} />
        <AppRoute exact path={ROUTES.ACCESS_DENIED} component={AccessDeniedPage} />
        <AppRoute exact path={ROUTES.PAGE_NOT_FOUND} component={PageNotFound} />
        <AppRoute exact path={ROUTES.PROFILE} component={ProfilePage} />
        <AppRoute exact path={ROUTES.USER} component={UsersPage} />
        <AppRoute exact path={`${ROUTES.USER}/add-new`} component={UserForm} />
        <AppRoute exact path={`${ROUTES.USER}/edit/:id`} component={UserForm} />
        <AppRoute exact path={ROUTES.EVENT} component={ManageEvent} />
        <AppRoute exact path={ROUTES.DETAIL_EVENT} component={EventDetail} />
        
        <AppRoute exact path={ROUTES.MANAGE_DOCS} component={ManageDocument} />
        <AppRoute exact path={ROUTES.MANAGE_BIEUQUYET_INFO} component={ManageBieuQuyet} />
        <AppRoute exact path={ROUTES.MANAGE_BINHBAU_INFO} component={ManageBinhBau} />

        <AppRoute exact path={ROUTES.UYQUYEN} component={UyQuyen} />
        <AppRoute exact path={`${ROUTES.BIEUQUYET}/:id`} component={BieuQuyet} />
        <AppRoute exact path={`${ROUTES.BINHBAU}/:id`} component={BinhBau} />

        
        
        
        <Redirect from='**' to={ROUTES.PAGE_NOT_FOUND} />
      </Switch>
    </Router>
  );
}

export default App;
