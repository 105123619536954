import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import './index.css';
import { getUserInfo } from "../../../redux/actions/userAction";
import API from "../../../services/API";
import { ROUTES, USER_ROLE } from "../../../constants/app";
import { history } from "../../../router/history";
import {toast} from 'react-toastify'
const PageForward = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.userInfo);

    useEffect(() => {
        dispatch(getUserInfo());
    }, [])

    useEffect(async () => {
        if (currentUser && currentUser.role === USER_ROLE.SHAREHOLDER){
            try{
                const res = await API.event.get_event_info('latest')
                history.push(`${ROUTES.EVENT}/view/${res._id}`)
            }catch(error){
                toast.error('Chưa có sự kiện nào')
                history.push(ROUTES.PROFILE)
            }
            
        }

        if (currentUser && currentUser.role === USER_ROLE.ADMIN){
            history.push(`${ROUTES.EVENT}`)
        }

        
        
    },[currentUser])
    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title="Page Not Found"
            icon="fas fa-exclamation-triangle"
            breadcrumb="404"
        />
        <div className="notfound">
            <div className="notfound-404">
                <div></div>
                <h1>307</h1>
            </div>
            <h2>Page forward</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <Link className="btn btn-light text-muted ml-0 btn-sm mt-3" to="/">
                <i className="fas fa-arrow-left mr-2"></i>
                <span>Dashboard</span>
            </Link>
        </div>
    </div>
}
export default PageForward;