import UserService from './userService';
import EventService from './eventService';
import BieuQuyetContentService from './bieuquyetContentService';
import BinhBauContentService from './binhbauContentService';
import VoteService from './voteService';
import UploadService from './uploadService';
const API = {
    user: new UserService(),
    event: new EventService(),
    bieuquyet_content: new BieuQuyetContentService(),
    binhbau_content: new BinhBauContentService(),
    vote_ticket: new VoteService(),
    upload: new UploadService(),
};

export default API;