import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import { CSVLink } from 'react-csv';
import { Card, Select } from 'antd';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { USER_ROLE, ROUTES, API_URLs, DEFAULT_AVATAR } from "../../../constants/app";
import { MESSAGES } from "../../../constants/message";
import { history } from "../../../router/history";
import { toast } from 'react-toastify';
import API from '../../../services/API';
import moment from 'moment-timezone'
import fileDownload from 'js-file-download';
import './index.css';
import { CornerUpLeft, Trash2 } from 'react-feather'
const GENDER_TEXT = {
    MALE: 'Mr ',
    FEMALE: 'Ms '
}
const { Option } = Select;
const ManageBinhBau = (props) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const currentUser = useSelector(state => state.user.userInfo);
    const [viewMode, setViewMode] = useState('');
    const [bb_content, setBBContent] = useState('');
    const [bb_title, setBBTitle] = useState('');
    const [bb_allungvien, setBBAllUngvien] = useState([]);
    const [bb_ungvien, setBBUngvien] = useState([]);
    const [bb_ungvienInfo, setBBUngvienInfo] = useState([]);
    const [eventID, setEventID] = useState('');
    const [bbExpire, setBBExpire] = useState(moment());
    const [bbExpireTime, setBBExpireTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('HH:mm'));
    const [bbExpireDate, setBBExpireDate] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'));

    const [bbStart, setBBStart] = useState(moment());
    const [bbStartTime, setBBStartTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('HH:mm'));
    const [bbStartDate, setBBStartDate] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'));

    const [soCD, setSoCD] = useState(0);
    const [soCP, setSoCP] = useState(0);
    const [soCPDaBau, setSoCPDaBau] = useState(0);
    const [tileThamGia, setTiLeThamGia] = useState(0);
    const [ketQuaBau, setKQBau] = useState({});
    const [attachment, setAttachment] = useState(null)

    const get_bb_info = async (id) => {
        try {
            const bb_info = await API.binhbau_content.get_binhbau(id);
            setBBContent(bb_info.content);
            setBBTitle(bb_info.title);
            setEventID(bb_info.event_id._id);
            console.log(bb_info)
            const expired_time = moment.utc(bb_info.expired_at).tz('Asia/Ho_Chi_Minh')
            setBBExpire(expired_time);
            setBBExpireDate(expired_time.format('YYYY-MM-DD'))
            setBBExpireTime(expired_time.format('HH:mm'))
            setAttachment(bb_info.attachment)

            console.log(bb_info.started_time)
            const started_time = moment.utc(bb_info.started_at).tz('Asia/Ho_Chi_Minh')
            setBBStart(started_time);
            setBBStartDate(started_time.format('YYYY-MM-DD'))
            setBBStartTime(started_time.format('HH:mm'))

            let ungvien = [];
            for (let ungvien_ of bb_info.ungvien) {
                ungvien.push(ungvien_._id);
            }
            console.log(ungvien)
            setBBUngvien(ungvien);
            setBBUngvienInfo(bb_info.ungvien)
            console.log(bb_info.result)
            setSoCD(bb_info.result.so_cd)
            setSoCP(bb_info.result.so_cp_total)
            setTiLeThamGia(bb_info.result.ty_le_tham_gia)
            setKQBau(bb_info.result.phieu_bau)
            setSoCPDaBau(bb_info.result.so_cp_dabau)

        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    useEffect(() => {
        if (bbExpireDate || bbExpireTime) {
            console.log(bbExpireDate, bbExpireTime)
            setBBExpire(moment.tz(`${bbExpireDate} ${bbExpireTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Ho_Chi_Minh'))
        }
    }, [bbExpireDate, bbExpireTime])

    useEffect(() => {
        if (bbStartDate || bbStartTime) {
            console.log(bbStartDate, bbStartTime)
            setBBStart(moment.tz(`${bbStartDate} ${bbStartTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Ho_Chi_Minh'))
        }
    }, [bbStartDate, bbStartTime])

    useEffect(async () => {
        if (currentUser && currentUser.role !== USER_ROLE.ADMIN) {
            history.push(ROUTES.ACCESS_DENIED);
        }
        const mode = props?.match?.params?.mode;
        if (mode) {
            setViewMode(mode)
        }

        if (mode !== 'add-new') {
            console.log('Get info')
            await get_bb_info(props?.match?.params?.id);
        }
        const all_ungvien_api = await API.user.get(API_URLs.USER, { page: 0, pagesize: 1, type: USER_ROLE.SHAREHOLDER })
        // let all_ungvien = []
        // for (let unvien of all_ungvien_api.data){
        //     all_ungvien.push({value: unvien._id, label: unvien.name})
        // }
        setBBAllUngvien(all_ungvien_api.data)
    }, [props, currentUser])


    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false || !currentUser) {
            return
        }



        let postData = {
            title: bb_title,
            content: bb_content,
            ungvien: bb_ungvien,
            event_id: props?.match?.params?.id,
            expired_at: bbExpire,
            started_at: bbStart,
            attachment,
        }
        try {
            console.log('SUBBIT', postData)

            // // toast.success(MESSAGES.UpdateSuccess);
            if (viewMode === 'add-new') {
                if (bbExpire - bbStart < 0) {
                    toast.error('Thời gian bắt đầu phải trước thời gian kết thúc');
                    return;
                }
                await API.binhbau_content.create(postData);
                toast.success('Tạo bình bầu thành công');
                history.push(`${ROUTES.EVENT}/view/${props?.match?.params?.id}`)
            }

            if (viewMode === 'view') {
                history.push(`${ROUTES.MANAGE_BINHBAU}/edit/${props?.match?.params?.id}`)
            }

            if (viewMode === 'edit') {
                if (bbExpire - bbStart < 0) {
                    toast.error('Thời gian bắt đầu phải trước thời gian kết thúc');
                    return;
                }
                await API.binhbau_content.update_bb(props?.match?.params?.id, postData);
                toast.success('Cập bình bầu thành công');
                history.push(`${ROUTES.EVENT}/view/${eventID}`)
            }

            // dispatch(getUserInfo());
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };

    const onExport = async () => {
        toast.info("Đang export dữ liệu, vui lòng đợi.");
        try {
            const res = await API.binhbau_content.export_bb(props?.match?.params?.id)
            const blob = new Blob([res], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            a.href = url;
            a.download = `kq_binh_bau_${bb_title}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

            // const filename = res.file_url.split("/").pop();
            // await downloadBlob(res.file_url, filename);
            toast.success("Export dữ liệu thành công.");
        } catch (error) {
            toast.error("Export lỗi: " + error.message);
        }
    };


    const getExportData = async () => {


        const res = await API.binhbau_content.export_bb(props?.match?.params?.id)
        console.log(res)
        var a = window.document.createElement('a');
        var universalBOM = "\uFEFF";
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + res));
        a.setAttribute('download', `kq_binh_bau_${bb_title}.csv`);
        window.document.body.appendChild(a);
        a.click();
        fileDownload(res, `kq_binh_bau_${bb_title}.csv`)
    }
    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title={(viewMode === 'add-new') ? 'Thêm mới bình bầu' : 'Chi tiết bình bầu'}
            icon="fas fa-poll"
            breadcrumb="Quản lý bình bầu"
        />

        <Card>
            <a className="mb-4" onClick={() => history.push(`/event/view/${eventID}`)}> <CornerUpLeft /> Quay về sự kiện</a>
            <Row md='1'>
                <Card className="mb-3">

                    <h5 className="mb-3">Thông tin chung</h5>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlName">
                                    <Form.Label>Tiêu đề bình bầu <span>*</span></Form.Label>
                                    <Form.Control required value={bb_title} placeholder='Tiêu đề bình bầu' onChange={(e) => setBBTitle(e.target.value)} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Tiêu đề bình bầu không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlCType">
                                    <Form.Label>Nội dung bình bầu <span>*</span></Form.Label>
                                    <Form.Control required value={bb_content} placeholder='Nội dung bình bầu' onChange={(e) => { setBBContent(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Nội dung bình bầu không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlStatus">
                                    <Form.Label>Ứng viên đề cử <span>*</span></Form.Label>
                                    <Select
                                        className="mr-3 text-left"
                                        placeholder='Chọn ứng viên'
                                        allowClear='auto'
                                        mode="multiple"
                                        value={bb_ungvien}
                                        maxTagCount='responsive'

                                        // tagRender={()=>{}}
                                        // allowClear
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 || option.props?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                        onChange={(value) => {
                                            if (viewMode === 'view') {
                                                return;
                                            }
                                            // let ung_vien_ids = []
                                            // console.log(objs)
                                            // for (let ung_v of objs){
                                            //     ung_vien_ids.push(ung_v.key)
                                            // }
                                            setBBUngvien(value)
                                        }}
                                    >
                                        {bb_allungvien.map((value, idx) => {
                                            return <Option key={value._id} value={value._id}>{`${value.name} (${value.email})`}</Option>
                                        })}

                                    </Select>
                                </Form.Group>
                            </Col>
                        </Row>



                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlStartTime">
                                    <Form.Label>Thời gian bắt đầu <span>*</span></Form.Label>
                                    <Form.Control type='date' required value={bbStartDate} onChange={(e) => { console.log(e.target.value); setBBStartDate(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control type='time' required value={bbStartTime} onChange={(e) => { console.log(e.target.value); setBBStartTime(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Vui lòng chọn thời điểm bắt đầu.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlStatus">
                                    <Form.Label>Thời gian kết thúc <span>*</span></Form.Label>
                                    <Form.Control type='date' required value={bbExpireDate} onChange={(e) => { console.log(e.target.value); setBBExpireDate(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control type='time' required value={bbExpireTime} onChange={(e) => { console.log(e.target.value); setBBExpireTime(e.target.value) }} readOnly={viewMode === 'view'} />
                                    <Form.Control.Feedback type="invalid">
                                        Vui lòng chọn thời điểm kết thúc.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlAtt">
                                    <Form.Label>Tệp đính kèm {attachment && viewMode === "edit" && <a onClick={() => setAttachment('')}><Trash2 className='ms-1' size={15} /></a>}</Form.Label>
                                    <div>
                                        {attachment && <a href={`${window.API_BASE_URL}${API_URLs.DOWNLOAD}/${attachment}`} download={attachment}>{attachment}</a>}
                                    </div>
                                    {viewMode !== "view" && <input type='file' onChange={async (e) => {
                                        const file = e.target.files[0]
                                        const formData = new FormData()
                                        formData.append("file", file)
                                        API.upload.uploadAttachment(formData).then((res) => {
                                            console.log(res.file_path)
                                            setAttachment(res.file_path);
                                        })
                                    }} />}
                                </Form.Group>
                            </Col>
                        </Row>

                        {(currentUser && currentUser.role === USER_ROLE.ADMIN) ? <div className="mt-3">
                            <Button type="submit" variant="primary">{(viewMode === 'add-new') ? 'Thêm mới' : (viewMode === 'view') ? 'Sửa' : 'Lưu thay đổi'}</Button>
                        </div> : <div />
                        }

                    </Form>

                </Card>
            </Row>

            {(viewMode === 'view') ? <Row md='1'>
                <Card className="mb-3">
                    <h5 className="mb-3">Kết quả bình bầu</h5>
                    <Row>
                        <Col md="4">

                            <Button
                                color="primary"
                                onClick={() => { onExport() }}
                                size={'small'}
                                variant="primary"
                            >
                                Export
                            </Button>
                        </Col>
                    </Row>
                    <Row >
                        <Col md="3">
                            <Card title='Tổng cổ đông'>
                                <h4>{soCD}</h4>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card title='Tổng số cổ phần'>
                                <h4>{soCP}</h4>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card title='Tổng số cổ phiếu đã bầu'>
                                <h4>{soCPDaBau}</h4>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card title='Tỷ lệ tham gia'>
                                <h4>{Math.round(tileThamGia * 100)}%</h4>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={[16, 24]}>
                        {bb_ungvienInfo?.map((item, key) => {
                            return <Card className="gutter-row" span={6}
                                style={{
                                    width: 200,
                                    height: 300
                                }}
                                cover={<img alt="example" style={{ height: '150px', width: '70%', margin: 'auto' }} src={(item.avatar && item.avatar !== DEFAULT_AVATAR) ? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${item.avatar}` : DEFAULT_AVATAR} />}
                            >
                                <h6 style={{ textAlign: 'center', height: '35px' }} >{GENDER_TEXT[item.gender]} {item.name}</h6>
                                <Form.Group controlId="ctrlName">
                                    <Form.Label >Số lượng phiếu bầu</Form.Label>
                                    <Form.Control readOnly value={ketQuaBau[item.email]} />
                                </Form.Group>

                            </Card>
                        })}
                    </Row>


                </Card>
            </Row> : <div />}
        </Card>
    </div>
}
export default ManageBinhBau;