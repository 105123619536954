import { Col, Row, Button, Form } from 'react-bootstrap';
import React from 'react';
import './index.css';
import MyCard from '../../components/MyCard';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/app';

const RecoverPasswordPage = () => {
    return <div className="ne-auth-page ne-login-page">
        <div className="container">
            <Row className="justify-content-center">
                <Col xs="11" md="6" lg="5">
                    <div className="ne-card-auth">
                        <div className="text-center mb-5">
                            <img src="/images/logo.png" width="320" alt="LOGO ALT" />
                        </div>
                        <MyCard title="Recover Password">
                            <Form>
                                <Form.Group controlId="ctrlUsername">
                                    <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                    <Form.Control required={true} type="text" />
                                </Form.Group>
                                <hr />
                                <div className="mt-3 pb-2">
                                    <Button variant="primary" type="submit" className="px-4">Send Reset Password</Button>
                                </div>
                            </Form>
                        </MyCard>
                        <div className="text-center">
                            <Link to={ROUTES.LOGIN} className="opacity-50">
                                <i className="fas fa-long-arrow-alt-left mr-2"></i>
                                <span>Back to Login page</span>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
}

export default RecoverPasswordPage;