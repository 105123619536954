import { Card, Image } from "antd";
import { useEffect, useState } from "react";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import MyPageHeader from "../../../../components/MyPageHeader";
import { DEFAULT_AVATAR, ROUTES, USER_GENDER, USER_ROLE } from "../../../../constants/app";
import { MESSAGES } from "../../../../constants/message";
import { history } from "../../../../router/history";
import API from "../../../../services/API";

const UserForm = (props) => {
    const [currentId, setCurrentId] = useState(null);
    const [validated, setValidated] = useState(false);

    const [role, setRole] = useState(USER_ROLE.ADMIN);
    const [avatar, setAvatar] = useState(DEFAULT_AVATAR);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [so_cp, setSoCP] = useState(0);
    const [gender, setGender] = useState(USER_GENDER.FEMALE);

    useEffect(async () => {
        if (props?.match?.params?.id) {
            const res = await API.user.getById(props.match.params.id);
            if (res) {
                setCurrentId(res._id);
                setUsername(res.cccd || '');
                setEmail(res.email || '');
                setPhone(res.phone || '');
                setName(res.name || '');
                setRole(res.role || USER_ROLE.ADMIN);
                setAvatar(res.avatar || DEFAULT_AVATAR);
                setSoCP(res.so_cp);
                setGender(res.gender);
            } else {
                history.push(ROUTES.PAGE_NOT_FOUND);
            }
        }
    }, [props])

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }
        const postData = {
            "name": name,
            "role": role,
            "phone": phone,
            "email": email,
            "avatar": avatar,
            "so_cp": so_cp,
            "gender": gender,
            'cccd': username
        }
        try {
            if (!currentId) {
                
                postData['password'] = password;
                await API.user.create(postData);
                toast.success(MESSAGES.CreateSuccess);
            } else {
                if (password) {
                    postData['password'] = password;
                }
                await API.user.update(currentId, postData);
                toast.success(MESSAGES.UpdateSuccess);
            }
            history.push(ROUTES.USER);
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };


    const handleBackClick = () => {
        history.push(ROUTES.USER);
    }


    return <div className="ne-page-body ne-page-user-form">
        <MyPageHeader
            title={`${currentId ? 'Thay đổi thông tin người dùng' : 'Thêm thông tin người dùng'}`}
            icon="fas fa-user-shield"
            breadcrumb={`Người dùng / ${currentId ? 'Cập nhật' : 'Thêm mới'}`}
            header_action_icon="fas fa-arrow-left"
            header_action_class="btn-light text-muted ml-3"
            header_action="Back to list"
            onHeaderClick={() => handleBackClick()}
        />
        <Row>
            <Col lg="3">
                <Card>
                    <div className="profile-img-edit position-relative">
                        <Image
                            className="profile-pic rounded w-100"
                            alt="profile-pic"
                            src={avatar || DEFAULT_AVATAR}
                            preview={false}
                            fallback={DEFAULT_AVATAR}
                        />
                    </div>
                </Card>
            </Col>
            <Col lg="9">
                <Card className="mb-3">
                    <h5 className="mb-3">Thông tin chung</h5>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlName">
                                    <Form.Label>Họ và tên <span>*</span></Form.Label>
                                    <Form.Control required placeholder="Họ và tên" value={name} onChange={(e) => setName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Họ và tên không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlEmail">
                                    <Form.Label>Email <span>*</span></Form.Label>
                                    <Form.Control type="Email" required placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                    <Form.Control.Feedback type="invalid">
                                        {email && email.trim() === '' ? 'Email không được trống.' : 'Email không đúng.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlUsername">
                                    <Form.Label>CMND/CCCD <span>*</span></Form.Label>
                                    <Form.Control required value={username} placeholder="CMND/CCCD" onChange={(e) => setUsername(e.target.value)}/>
                                    <Form.Control.Feedback type="invalid">
                                        CMND/CCCD không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                {
                                    !currentId ? <Form.Group controlId="ctrlPassword">
                                        <Form.Label>Mật khẩu <span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="***************" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Mật khẩu không được trống.
                                        </Form.Control.Feedback>
                                    </Form.Group> : <Form.Group controlId="ctrlPassword">
                                        <Form.Label>Mật khẩu</Form.Label>
                                        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                }
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlPhone">
                                    <Form.Label>Điện thoại <span>*</span></Form.Label>
                                    <Form.Control required placeholder="Số điện thoại" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Điện thoại không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlStatus">
                                    <Form.Label>Quyền <span>*</span></Form.Label>
                                    <Form.Control as="select" className="form-select" value={role} onChange={(e) => setRole(e.target.value)}>
                                        <option value={USER_ROLE.ADMIN}>Quản trị</option>
                                        <option value={USER_ROLE.SHAREHOLDER}>Cổ đông</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group controlId="ctrlPhone">
                                    <Form.Label>Số cố phiếu <span>*</span></Form.Label>
                                    <Form.Control required value={so_cp} onChange={(e) => setSoCP(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Số cổ phiếu không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group controlId="ctrlGender">
                                    <Form.Label>Giới tính <span>*</span></Form.Label>
                                    <Form.Control as="select" className="form-select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                        <option value={USER_GENDER.FEMALE}>Nữ</option>
                                        <option value={USER_GENDER.MALE}>Nam</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <Button type="submit" variant="primary">Lưu thay đổi</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    </div>
}
export default UserForm;