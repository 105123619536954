export const INITIAL_STATE_LOGIN_REQUEST = 'INITIAL_STATE_LOGIN_REQUEST';
export const INITIAL_STATE_LOGIN = 'INITIAL_STATE_LOGIN';
export const INITIAL_STATE_LOGIN_ERROR = 'INITIAL_STATE_LOGIN_ERROR';

export const INITIAL_STATE_LOG_OUT_REQUEST = 'INITIAL_STATE_LOG_OUT_REQUEST';
export const INITIAL_STATE_LOG_OUT = 'INITIAL_STATE_LOG_OUT';
export const INITIAL_STATE_LOG_OUT_ERROR = 'INITIAL_STATE_LOG_OUT_ERROR';

export const INITIAL_STATE_PROFILE_REQUEST = 'INITIAL_STATE_PROFILE_REQUEST';
export const INITIAL_STATE_PROFILE = 'INITIAL_STATE_PROFILE';
export const INITIAL_STATE_PROFILE_ERROR = 'INITIAL_STATE_PROFILE_ERROR';

export const INITIAL_SOCKET_IO = 'INITIAL_SOCKET_IO';
export const DESTROY_SOCKET_IO = 'DESTROY_SOCKET_IO';

export const STORE_FILTER = 'STORE_FILTER';
export const CLEAR_STORED_FILTER = 'CLEAR_STORED_FILTER';
export const APPLY_STORED_FILTER = 'APPLY_STORED_FILTER';