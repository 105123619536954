import { Card, Image, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DEFAULT_AVATAR, API_URLs } from "../../../constants/app";
import { MESSAGES } from "../../../constants/message";
import { getUserInfo } from "../../../redux/actions/userAction";
import API from "../../../services/API";
import $ from 'jquery';
import NextPageHeader from "../../../components/MyPageHeader";

const ProfilePage = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.userInfo);
    const [validated, setValidated] = useState(false);
    const [passwordValidated, setPasswordValidated] = useState(false);
    // const [isUpdateAvatar, setIsUpdateAvatar] = useState(false);

    const [role, setRole] = useState('USER');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [avatar, setAvatar] = useState(DEFAULT_AVATAR);

    useEffect(async () => {
        if (currentUser) {
            // if (!isUpdateAvatar) {
            setUsername(currentUser.cccd);
            setName(currentUser.name);
            setRole(currentUser.role);
            setPassword(currentUser.password);
            setEmail(currentUser.email);
            setPhone(currentUser.phone);
            // }
            setAvatar(currentUser.avatar?currentUser.avatar:DEFAULT_AVATAR);
            // setIsUpdateAvatar(false);
        }
    }, [currentUser])

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false || !currentUser) {
            return
        }
        const postData = {
            "name": name,
            // "role": role,
            "phone": phone,
            "email": email,
            "avatar": (avatar===DEFAULT_AVATAR)?'':avatar
        }
        try {
            await API.user.update_my_acc(postData);
            toast.success(MESSAGES.UpdateSuccess);
            dispatch(getUserInfo());
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };

    const handleSubmitChangePassword = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setPasswordValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false || !currentUser) return;
        if (newpassword && repassword && newpassword !== repassword) return;
        const postData = {
            // "oldpass": oldpassword,
            "password": newpassword
        }
        try {
            await API.user.update_my_acc(postData);
            toast.success(MESSAGES.UpdateSuccess);
            dispatch(getUserInfo());
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };

    const handleFileUploadChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            try {
                const res = await API.upload.upload_avatar(currentUser.id,formData);
                setAvatar(res.file_path);
                // setIsUpdateAvatar(true);
                // toast.success(MESSAGES.ChangeAvatarSuccess);
                // dispatch(getUserInfo());
            } catch (error) {
                toast.error(error?.response?.data?.detail);
            }
        }
    }

    const handleSelectFile = () => {
        $('#fFile').val('');
        $('#fFile').trigger('click');
    }

    return <div className="ne-page-body ne-page-customers">
        <NextPageHeader
            title="Tài khoản"
            icon="fas fa-user"
            breadcrumb="Profile"
        />
        <Row>
            <Col lg="3">
                <Card>
                    <div className="profile-img-edit position-relative">
                        <Image
                            className="profile-pic rounded w-100"
                            alt="profile-pic"
                            src={(avatar && avatar!==DEFAULT_AVATAR)?`${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${avatar}`:DEFAULT_AVATAR}
                            preview={false}
                            fallback={DEFAULT_AVATAR}
                        />
                        <div className="upload-icone bg-primary" onClick={handleSelectFile}>
                            <Tooltip title="Change photo">
                                <i className="fas fa-camera text-white"></i>
                            </Tooltip>

                            <input className="file-upload" type="file" accept="image/*" id="fFile" onChange={handleFileUploadChange} />
                        </div>
                    </div>
                </Card>
            </Col>
            <Col lg="9">
                <Card className="mb-3">
                    <h5 className="mb-3">Thông tin chung</h5>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="ctrlName">
                                    <Form.Label>Họ và tên <span>*</span></Form.Label>
                                    <Form.Control required value={name} onChange={(e) => setName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Họ và tên không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlUsername">
                                    <Form.Label>CMND/CCCD <span>*</span></Form.Label>
                                    <Form.Control required value={username} />
                                    <Form.Control.Feedback type="invalid">
                                    Tên đăng nhập không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlEmail">
                                    <Form.Label>Email <span>*</span></Form.Label>
                                    <Form.Control type="Email" required readOnly value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                    <Form.Control.Feedback type="invalid">
                                        {email && email.trim() === '' ? 'Email is required.' : 'Email is invalid.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="ctrlPhone">
                                    <Form.Label>Số điện thoại <span>*</span></Form.Label>
                                    <Form.Control required value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Số điện thoại không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <Button type="submit" variant="primary">Lưu thay đổi</Button>
                        </div>
                    </Form>
                </Card>
                <Card>
                    <h5 className="mb-3">Bảo mật</h5>
                    <Form noValidate validated={passwordValidated} onSubmit={handleSubmitChangePassword}>
                        <Row>
                            <Col md="4">
                                <Form.Group controlId="ctrlOldPassword">
                                    <Form.Label>Mật khẩu cũ <span>*</span></Form.Label>
                                    <Form.Control type="password" required value={oldpassword} onChange={(e) => setOldPassword(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Mật khẩu không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <Form.Group controlId="ctrlNewPassword">
                                    <Form.Label>Mật khẩu mới <span>*</span></Form.Label>
                                    <Form.Control type="password" required value={newpassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Mật khẩu không được trống.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <Form.Group controlId="ctrlRePassword">
                                    <Form.Label>Nhập lại mật khẩu <span>*</span></Form.Label>
                                    <Form.Control className={newpassword && repassword && newpassword !== repassword ? 'invalid' : ''} type="password" required value={repassword} onChange={(e) => setRePassword(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Mật khẩu không được trống.
                                    </Form.Control.Feedback>
                                    {newpassword && repassword && newpassword !== repassword && <div className="invalid-feedback d-block">Mật khẩu không khớp.</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <Button type="submit" variant="primary">Thay đổi mật khẩu</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    </div>
}
export default ProfilePage;