import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../../components/MyPageHeader";
import { Form } from 'react-bootstrap';
import { Table, Card, Tooltip, Image, Button as ButtonANT } from 'antd';
import { Link } from "react-router-dom";
import { getUserInfo } from "../../../../redux/actions/userAction";
import { API_URLs, ITEMS_PER_PAGE, ROUTES, USER_ROLE, DATE_TIME_FORMAT } from "../../../../constants/app";
import swal from "sweetalert";
import { MESSAGES } from "../../../../constants/message";
import './index.css';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import $ from 'jquery';
import { toast } from "react-toastify";
import { history } from "../../../../router/history";
import API from "../../../../services/API";
import UyQuyen from "../../../ShareHolder/UyQuyen";


const BBBQ_STATE = {
    NEW: 'Mới',
    INPROGRESS: 'Đang diễn ra',
    DONE: 'Kết thúc'
}
const DEFAULT_COVER = 'https://i.pinimg.com/736x/17/08/9f/17089f4c221d8a8d19371546cf706fa0--twitter-cover-cover-pics.jpg';
const EventDetail = (props) => {
    const dispatch = useDispatch();
    const [loadingBB, setLoadingBB] = useState(false);
    const [loadingBQ, setLoadingBQ] = useState(false);
    const currentUser = useSelector(state => state.user.userInfo);
    const [validated, setValidated] = useState(false);
    const [viewMode, setViewMode] = useState('');
    const [pageIndexBB, setPageIndexBB] = useState(1);
    const [pageIndexBQ, setPageIndexBQ] = useState(1);
    const [keywordBB, setKeywordBB] = useState('');
    const [keywordBQ, setKeywordBQ] = useState('');
    const [totalBBItems, setTotalBBItems] = useState(0);
    const [totalBQItems, setTotalBQItems] = useState(0);
    const [dataSourceBB, setDataSourceBB] = useState([]);
    const [dataSourceBQ, setDataSourceBQ] = useState([]);

    const [event_title, setEventTitle] = useState('');
    const [event_image, setEventImage] = useState(DEFAULT_COVER);
    const [event_so_codong, setSoCD] = useState('');
    const [event_so_cophieu, setSoCP] = useState('');
    const [event_cur_cophieu, setSoCPSoHuu] = useState('');
    const [event_time, setEventTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'))

    const [validUyQuyen, setValidUyQuyen] = useState(true);
    const [email, setEmail] = useState(null);

    const onChangeUyquyen = (e) => {
        console.log('Set valid uy quyen false')
        setValidUyQuyen(e)
        get_event_info(props?.match?.params?.id);
        // get_bieu_quyet_info(content.id, currentUser._id);
    }

    const get_event_info = async (id) => {
        try {
            const event = await API.event.get_event_info(id);
            const info = await API.vote_ticket.get_uyquyen({ event_id: id })
            console.log(info)
            console.log(event);
            setEventImage(event.image);
            setEventTitle(event.title);
            setSoCD(event.so_codong);
            setSoCP(event.so_cophieu);
            setSoCPSoHuu(event.cur_cophieu);
            setEmail(info.email)
            const event_time = moment.utc(event.event_time).tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD')
            setEventTime(event_time)
            // setDataSource(event);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    const get_bb_list = async (pageIndex, keyword) => {
        try {
            setLoadingBB(true);
            const params = {
                page: pageIndex,
                pagesize: ITEMS_PER_PAGE,
                event_id: props?.match?.params?.id,
                q: keyword
            };
            const bb_list = await API.binhbau_content.get_list_binhbau(params);
            let bb_list_tmp = Object.assign([], bb_list.data);
            setTotalBBItems(bb_list.total);
            for (let i = 0; i < bb_list_tmp.length; i++) {
                bb_list_tmp[i].type_bq = 'BB';
            }
            setDataSourceBB(bb_list_tmp);
            setLoadingBB(false);
        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            setLoadingBB(false);
        }
    }

    const get_bq_list = async (pageIndex, keyword) => {
        try {
            setLoadingBQ(true);
            const params = {
                page: pageIndex,
                pagesize: ITEMS_PER_PAGE,
                event_id: props?.match?.params?.id,
                q: keyword
            };
            const bq_list = await API.bieuquyet_content.get_list_bieuquyet(params);
            let bq_list_tmp = Object.assign([], bq_list.data);
            setTotalBQItems(bq_list.total);
            for (let i = 0; i < bq_list_tmp.length; i++) {
                bq_list_tmp[i].type_bq = 'BQ';
            }
            console.log(bq_list_tmp)
            setDataSourceBQ(bq_list_tmp);
            setLoadingBQ(false);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            setLoadingBQ(false);
        }
    }
    useEffect(async () => {
        if (props?.match?.params?.mode) {
            setViewMode(props?.match?.params?.mode)
        }

        const id = props?.match?.params?.id
        if (id) {
            await get_event_info(id)

        }
    }, [props]);

    useEffect(() => {
        dispatch(getUserInfo());
        get_bq_list(pageIndexBQ, keywordBQ);
        get_bb_list(pageIndexBB, keywordBB);

    }, [])

    let columns_tmp = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            width: '20%',
            render: (text, item) => {
                return <b>{text}</b>
                // let link_to = `${ROUTES.BIEUQUYET}/${item._id}`
                // if (item.type === 'BINHBAU') {
                //     link_to = `${ROUTES.BINHBAU}/${item._id}`
                // }
                // return <Link className="ant-bold" to={link_to}>
                //     <b>{text} {`(${TYPE_CONTENT[item.type]})`}</b>
                // </Link>
            }

        },
        {
            title: 'Nội dung',
            width: '50%',
            dataIndex: 'content',
            render: (text, item) => {
                return <span>{text}</span>;;
            }
        },

        {
            title: 'Kết thúc',
            width: '15%',
            className: 'text-center',
            dataIndex: 'expired_at',
            render: (text, item) => {
                return <span>{moment.utc(text).tz('Asia/Ho_Chi_Minh').format('HH:mm DD/MM')}</span>;;
            }
        },
        {
            title: 'Tùy chọn',
            dataIndex: '_id',
            width: '15%',
            className: 'text-center',
            render: (text, item) => {

                let link_to = `${ROUTES.BIEUQUYET}/${item._id}`
                if (item.type_bq === 'BB') {
                    link_to = `${ROUTES.BINHBAU}/${item._id}`
                }
                return <div className="text-center text-nowrap">
                    <Tooltip title="View">
                        <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={link_to}>
                            <i className="far fa-eye"></i>
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Download">
                        <ButtonANT shape="round" type="primary" className="ml-2" onClick={() => handleDownload(item._id)}>
                            <i className="fas fa-file-invoice"></i>
                        </ButtonANT>
                    </Tooltip> */}
                </div>
            }
        },
    ];
    const [columns, setColumns] = useState(columns_tmp)

    useEffect(() => {
        if (currentUser && currentUser.role === USER_ROLE.ADMIN) {
            columns_tmp[3].render = (text, item) => {
                let link_to = `${ROUTES.MANAGE_BIEUQUYET}`
                if (item.type_bq === 'BB') {
                    link_to = `${ROUTES.MANAGE_BINHBAU}`
                }
                if (viewMode === 'view') {
                    link_to = `${link_to}/view/${item._id}`
                    return <div className="text-center text-nowrap">
                        <Tooltip title="View">
                            <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={link_to}>
                                <i className="far fa-eye"></i>
                            </Link>
                        </Tooltip>
                    </div>
                }
                if (viewMode === 'edit') {
                    const link_to_view = `${link_to}/view/${item._id}`
                    const link_to_edit = `${link_to}/edit/${item._id}`
                    return <div className="text-center text-nowrap">
                        <Tooltip title="View">
                            <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={link_to_view}>
                                <i className="far fa-eye"></i>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Link className="ant-btn ant-btn-primary ant-btn-round ml-2" to={link_to_edit}>
                                <i className="far fa-edit"></i>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <ButtonANT shape="round" type="danger" className="ml-2" onClick={() => handleDelete(item._id, item.type_bq)}>
                                <i className="far fa-trash-alt"></i>
                            </ButtonANT>
                        </Tooltip>
                    </div>
                }

            }
            setColumns(columns_tmp)
        }
    }, [currentUser, viewMode])
    // const onChange = (pagination, filters, sorter, extra) => {
    //     setPageIndex(pagination.current);
    //     // loadUsersData(pagination.current, keyword);
    // }

    const handleKeywordBBChange = (e) => {
        setKeywordBB(e.target.value);
        get_bb_list(pageIndexBB, e.target.value);
        // loadUsersData(pageIndex, e.target.value);
    }
    const handleKeywordBQChange = (e) => {
        setKeywordBQ(e.target.value);
        get_bq_list(pageIndexBQ, e.target.value);
        // loadUsersData(pageIndex, e.target.value);
    }

    const handleDelete = (id, type) => {
        if (!id) return;
        swal({
            text: "Are you sure you want to delete this item?",
            buttons: {
                cancel: {
                    text: "No",
                    className: "btn btn-dark px-3",
                    closeModal: true,
                    visible: true
                },
                confirm: {
                    text: "Yes",
                    className: "btn btn-danger px-3"
                }
            },
        }).then(async (willDelete) => {
            if (willDelete) {
                console.log(type)
                if (type === 'BB') {
                    try {
                        await API.binhbau_content.delete_bb(id);
                        toast.success(MESSAGES.DeleteSuccess);
                        await get_bb_list(pageIndexBB, keywordBB);
                    } catch (error) {
                        toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
                    }
                }
                if (type === 'BQ') {
                    try {
                        await API.bieuquyet_content.delete_bq(id);
                        toast.success(MESSAGES.DeleteSuccess);
                        await get_bq_list(pageIndexBQ, keywordBQ);
                    } catch (error) {
                        toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
                    }
                    // try {
                    //     await API.event.delete_event(id)
                    //     toast.success(MESSAGES.DeleteSuccess);
                    //     await get_event();
                    // } catch (error) {
                    //     toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
                    // }
                }

            }
        });
    }

    const handleDownload = (id) => {
        console.log(`Handle download ${id}`)
    }

    const handleSelectFile = () => {
        $('#fFile').val('');
        $('#fFile').trigger('click');
    }

    const handleFileUploadChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log('Upload file change')
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            try {
                const res = await API.upload.upload_event_image(props?.match?.params?.id, formData);
                setEventImage(res.file_path);
                console.log(res)
                // setAvatar(res.avatar);
                // setIsUpdateAvatar(true);
                // toast.success(MESSAGES.ChangeAvatarSuccess);
                // dispatch(getUserInfo());
            } catch (error) {
                toast.error(error?.response?.data?.detail);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false || !currentUser) {
            return
        }

        const postData = {
            "title": event_title,
            "event_time": moment(event_time, "YYYY-MM-DD"),
            "image": event_image,
        }
        try {

            // toast.success(MESSAGES.UpdateSuccess);
            if (viewMode === 'add-new') {
                await API.event.create(postData);
                toast.success('Tạo sự kiện thành công');
                history.push(ROUTES.EVENT)
            }
            if (viewMode === 'view') {
                history.push(`${ROUTES.EVENT}/edit/${props?.match?.params?.id}`)
            }
            if (viewMode === 'edit') {
                await API.event.update_event(props?.match?.params?.id, postData);
                toast.success('Cập nhật sự kiện thành công');
                await get_event_info(props?.match?.params?.id);
            }

            // dispatch(getUserInfo());
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    };

    const handleAddNewBBClick = () => {
        history.push(`${ROUTES.MANAGE_BINHBAU}/add-new/${props?.match?.params?.id}`);
    }

    const handleAddNewBQClick = () => {
        history.push(`${ROUTES.MANAGE_BIEUQUYET}/add-new/${props?.match?.params?.id}`);
    }

    const onChangeBB = (pagination, filters, sorter, extra) => {
        console.log('On table change bb')
        setPageIndexBB(pagination.current);
        get_bb_list(pagination.current, keywordBB);
        // loadUsersData(pagination.current, keyword);
    }

    const onChangeBQ = (pagination, filters, sorter, extra) => {
        console.log('On table change BQ')
        setPageIndexBQ(pagination.current);
        get_bq_list(pagination.current, keywordBQ);
        // loadUsersData(pagination.current, keyword);
    }

    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title={viewMode !== 'add-new' ? 'Chi tiết sự kiện' : 'Tạo mới sự kiện'}
            icon="far fa-list-alt"
            breadcrumb="Sự kiện"
        />
        <Row md='1'>

            <Card>
                <div className="profile-img-edit position-relative">
                    <Image
                        style={{ width: '100%' }}
                        className="profile-pic rounded w-100"
                        alt="profile-pic"
                        src={(event_image && event_image !== DEFAULT_COVER) ? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${event_image}` : DEFAULT_COVER}
                        preview={false}
                        fallback={DEFAULT_COVER}
                    />
                    {(viewMode === 'view') ? <div /> :
                        <div className="upload-icone bg-primary" onClick={handleSelectFile}>
                            <Tooltip title="Change photo">
                                <i className="fas fa-camera text-white"></i>
                            </Tooltip>

                            <input disabled={viewMode === 'view'} className="file-upload" type="file" accept="image/*" id="fFile" onChange={handleFileUploadChange} />
                        </div>}
                </div>
            </Card>
        </Row>
        <Row md='1'>
            <Card className="mb-3">
                <h5 className="mb-3">Thông tin chung</h5>
                <Row >
                    <Col md="6">
                        <Card title='Tổng cổ đông'>
                            <h2>{event_so_codong}</h2>
                        </Card>
                    </Col>

                    {(currentUser && currentUser.role === USER_ROLE.SHAREHOLDER) ?
                        <Col md="6">
                            <Card title='Số cổ phiếu sở hữu'>
                                <h2>{event_cur_cophieu}</h2>
                            </Card>
                        </Col>
                        : <Col md="6">
                            <Card title='Tổng số cổ phiếu phát hành'>
                                <h2>{event_so_cophieu}</h2>
                            </Card>
                        </Col>
                    }
                </Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col md="6">
                            <Form.Group controlId="ctrlName">
                                <Form.Label>Tiêu đề sự kiện <span>*</span></Form.Label>
                                <Form.Control required value={event_title} placeholder='Tiêu đề sự kiện' onChange={(e) => setEventTitle(e.target.value)} readOnly={viewMode === 'view'} />
                                <Form.Control.Feedback type="invalid">
                                    Tiêu đề sự kiện không được trống.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="ctrlEventTime">
                                <Form.Label>Ngày diễn ra sự kiện <span>*</span></Form.Label>
                                <Form.Control type='date' required value={event_time} onChange={(e) => { setEventTime(e.target.value) }} readOnly={viewMode === 'view'} />
                                <Form.Control.Feedback type="invalid">
                                    Ngày diễn ra sự kiện không được trống.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {(currentUser && currentUser.role === USER_ROLE.ADMIN) ? <div className="mt-3">
                        <Button type="submit" variant="primary">{(viewMode === 'add-new') ? 'Thêm mới' : (viewMode === 'view') ? 'Sửa' : 'Lưu thay đổi'}</Button>
                    </div> : <div />
                    }

                </Form>

            </Card>
        </Row>
        {(currentUser && currentUser.role === USER_ROLE.SHAREHOLDER) ?
            <UyQuyen email={email} eventId={props?.match?.params?.id} onChange={onChangeUyquyen} />
            : <div />}

        {(viewMode !== 'add-new') && (validUyQuyen) ?
            <Card className="mb-3">
                <h5 className="mb-3">Nội dung sự kiện</h5>
                <Card className="mb-3">
                    <h5 className="mb-3">Bình bầu</h5>
                    <Row className="mb-3">
                        <Col md="3">
                            <ButtonANT disabled={currentUser && (currentUser.role !== USER_ROLE.ADMIN || viewMode === 'view')} type="primary" onClick={handleAddNewBBClick}>
                                <i className="mr-1 fas fa-plus"></i>
                                <span>Thêm bình bầu</span>
                            </ButtonANT>
                        </Col>

                        <Col md="6" className="mt-3 mt-md-0">
                            <input type="search" className="form-control ml-md-auto w-auto" placeholder="Tìm kiếm ..." value={keywordBB} onChange={handleKeywordBBChange} />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={dataSourceBB}
                        onChange={onChangeBB}
                        loading={loadingBB}
                        pagination={{ pageSize: ITEMS_PER_PAGE, total: totalBBItems }}
                        rowKey={record => record._id} />
                </Card>

                <Card className="mb-3">
                    <h5 className="mb-3">Biểu quyết</h5>
                    <Row className="mb-3">
                        <Col md="3">
                            <ButtonANT disabled={currentUser && (currentUser.role !== USER_ROLE.ADMIN || viewMode === 'view')} type="primary" onClick={handleAddNewBQClick}>
                                <i className="mr-1 fas fa-plus"></i>
                                <span>Thêm biểu quyết</span>
                            </ButtonANT>
                        </Col>

                        <Col md="6" className="mt-3 mt-md-0">
                            <input type="search" className="form-control ml-md-auto w-auto" placeholder="Tìm kiếm ..." value={keywordBQ} onChange={handleKeywordBQChange} />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={dataSourceBQ}
                        onChange={onChangeBQ}
                        loading={loadingBQ}
                        pagination={{ pageSize: ITEMS_PER_PAGE, total: totalBQItems }}
                        rowKey={record => record._id} />
                </Card>

            </Card> : <div />
        }
    </div>
}
export default EventDetail;