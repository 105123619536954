import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextPageHeader from "../../../components/MyPageHeader";
import { Row, Form, Col, Button } from 'react-bootstrap';
import { Card, Radio, Image } from 'antd';
import { getUserInfo } from "../../../redux/actions/userAction";
import { toast } from "react-toastify";
import moment from 'moment-timezone'
import './index.css';
import { DEFAULT_AVATAR, DEFAULT_COVER, API_URLs } from "../../../constants/app";
import { MESSAGES } from "../../../constants/message";
import API from "../../../services/API";
import { CornerUpLeft } from "react-feather";
import { history } from "../../../router/history";

const GENDER_TEXT = {
    MALE: 'Mr ',
    FEMALE: 'Ms '
}

let bau_ungvien = {}
const BinhBau = (props) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.userInfo);
    const [content, setContent] = useState({});
    const [validated, setValidated] = useState(false);
    const [uyquyen_type, setUyQuyenType] = useState('BBCN');
    const [ds_ng_uyquyen, setDSUyQuyen] = useState([]);
    const [ng_uyquyen, setNgUyQuyen] = useState(null);
    const [phieubbCaNhan, setPhieuBBCaNhan] = useState(0);
    const [phieubbUyQuyen, setPhieuBBUyQuyen] = useState(0);
    const [phieu_da_bb, setPhieuDaBB] = useState(0);
    const [phieu_chua_bb, setPhieuChuaBB] = useState(0);
    const [ung_vien, setUngVien] = useState([]);
    const [kqbb, setKQBinhBau] = useState({});
    const [ticket_id, setTicketID] = useState('');
    const [isBB, setIsBB] = useState(false);
    const [eventID, setEventID] = useState('');
    const [started_time, setStardTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh'));
    const [expired_time, setExpiredTime] = useState(moment.utc().tz('Asia/Ho_Chi_Minh'));
    const [notstart, setNotStart] = useState(false);

    useEffect(() => {
        getUserInfo()
    }, [])

    const get_uyquyen_list = async (event_id) => {
        try {

            const res = await API.vote_ticket.get_uyquyen_list({ event_id })
            let ds_uyquyen = []
            let so_cp_uyquyen = 0
            for (let r of res) {
                let label = r.name
                const params = {
                    codong_id: r._id,
                    content_id: props?.match?.params?.id,
                    content_type: 'BINHBAU'
                }
                const res_vote = await API.vote_ticket.get_info_vote(params)
                console.log(res_vote)
                so_cp_uyquyen += res_vote.co_dong_so_cp
                // if (res_vote.response){
                //     const response_json = JSON.parse(res_vote.response)
                //     console.log(response_json)
                //     setKQBieuQuyetUQ(response_json.BQ_RES)
                //     setKQBieuQuyet(response_json.BQ_RES)
                //     setIsBQUQ(true);
                // }
                ds_uyquyen.push({
                    label: label,
                    value: res_vote._id
                })
            }
            console.log(ung_vien.length)
            setPhieuBBUyQuyen(so_cp_uyquyen)
            setDSUyQuyen(ds_uyquyen);
        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    const get_binhbau_ticket_info = async (content_id, user_id, ungvien) => {
        try {
            const params = {
                codong_id: user_id,
                content_id: content_id,
                content_type: 'BINHBAU'
            }
            const res = await API.vote_ticket.get_info_vote(params)
            console.log(res)
            if (res.response) {
                const response_json = JSON.parse(res.response)
                setKQBinhBau(response_json.BB_RES)
                setIsBB(true);
                console.log(response_json.BB_RES)
                let phieu_da_bau = 0
                for (let key_ of Object.keys(response_json.BB_RES)) {
                    console.log(key_)
                    console.log(response_json.BB_RES[key_])


                    for (let u in ungvien) {
                        if (ungvien[u].email === key_) {
                            ungvien[u].sophieu = response_json.BB_RES[key_]
                            phieu_da_bau += response_json.BB_RES[key_]
                        }
                    }
                }
                console.log(ungvien)
                setPhieuDaBB(phieu_da_bau)
                setUngVien(ungvien);
            }
            setTicketID(res._id);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    // const bieuquyet_options = [
    //     {
    //         label: 'Bình bầu cá nhân',
    //         value: 'BBCN'
    //     },
    //     {
    //         label: 'Bình bầu ủy quyền',
    //         value: 'BBUQ'
    //     },

    // ]

    const get_bb_info = async (id) => {
        try {
            const bb_info = await API.binhbau_content.get_binhbau(id);
            console.log(bb_info)
            let ungvien = Object.assign([], bb_info.ungvien)
            await setUngVien(ungvien);
            setExpiredTime(moment.utc(bb_info.expired_at).tz('Asia/Ho_Chi_Minh'))
            setStardTime(moment.utc(bb_info.started_at).tz('Asia/Ho_Chi_Minh'))
            if (moment.utc(bb_info.started_at).tz('Asia/Ho_Chi_Minh') - moment.utc().tz('Asia/Ho_Chi_Minh') > 0){
                setNotStart(true)
            }
            setContent({
                title: bb_info.title,
                content: bb_info.content,
                type: 'BINHBAU',
                event_id: bb_info.event_id._id,
                attachment: bb_info.attachment
            })
            get_binhbau_ticket_info(bb_info._id, currentUser._id, ungvien);
            setPhieuBBCaNhan(currentUser.so_cp);
            get_uyquyen_list(bb_info.event_id._id)
            setEventID(bb_info.event_id._id)
            // setPhieuChuaBB(currentUser.so_cp * bb_info.ungvien.length);
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }
    }

    const onChangePhieuBau = (key, e) => {
        let e_num = parseInt(e)
        if (isNaN(e_num) || e_num < 0 ){
            e_num = 0
        }
        bau_ungvien[key] = parseInt(e_num)
        let phieu_da_bau = 0
        for (let key of Object.keys(bau_ungvien)) {
            phieu_da_bau += bau_ungvien[key]
        }
        console.log(phieu_da_bau)
        setPhieuDaBB(phieu_da_bau)
        // setPhieuChuaBB(phieubb - phieu_da_bb)
    }

    useEffect(() => {
        if (ung_vien.length > 0){
            const length_ung_vien = ung_vien.length
            
            setPhieuChuaBB(phieubbCaNhan*length_ung_vien + phieubbUyQuyen*length_ung_vien - phieu_da_bb)
            setPhieuDaBB(phieu_da_bb)
            // setPhieuBBCaNhan(phieubbCaNhan*length_ung_vien)
            // setPhieuBBUyQuyen(phieubbUyQuyen*length_ung_vien)
        }
        
    }, [phieu_da_bb, phieubbCaNhan, phieubbUyQuyen, ung_vien])
    useEffect(async () => {
        console.log(props?.match?.params?.id)
        if (props?.match?.params?.id && currentUser) {
            get_bb_info(props?.match?.params?.id);
        }

    }, [props, currentUser])

    // const changeBBTpe = async (event) => {
    //     setUyQuyenType(event.target.value);
    //     // if (event.target.value === 'BBCN') {
    //     //     setPhieuBB(Math.floor(Math.random() * 100));
    //     // }

    // }

    // const changeNgUyQuyen = async (event) => {
    //     setNgUyQuyen(event.target.value);
    //     // setPhieuBB(Math.floor(Math.random() * 100));
    // }
    const handleSubmit = async (event) => {
        console.log('SUBBMIT')
        
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }
        if (expired_time - moment.utc().tz('Asia/Ho_Chi_Minh') < 0){
            toast.error('Đã kết thúc bình bầu')
            return
        }

        if (uyquyen_type === 'BBCN') {
            if (phieu_chua_bb < 0) {
                toast.error('Số phiếu đã bình bầu không lớn hơn có thể bình bầu')
                return
            }
            try {
                const params = {
                    response: JSON.stringify({ BB_RES: bau_ungvien })
                }
                await API.vote_ticket.vote_ticket(ticket_id, params)
                console.log(ung_vien)
                get_binhbau_ticket_info(props?.match?.params?.id, currentUser._id, ung_vien);
                get_uyquyen_list(content.event_id)
                toast.success('Bình bầu cá nhân thành công')
            }
            catch (error) {
                toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
            }

        }

        // toast.success('Biểu quyết thành công')
    }
    return <div className="ne-page-body ne-page-not-found">
        <NextPageHeader
            title="Bình bầu"
            icon="fas fa-poll-h"
            breadcrumb="Bình bầu"
        />

        <Card>
            <a className="mb-4" onClick={() => history.push(`/event/view/${eventID}`)}> <CornerUpLeft /> Quay về sự kiện</a>
        </Card>
        <Card className="mb-3">
            <h5 className="mb-3">{content.title}</h5>
            <b className="mb-3 bold" style={{'color': 'red'}}>{`* Thời gian bình bầu từ ${started_time.format('HH:mm DD/MM/YYYY')} đến ${expired_time.format('HH:mm DD/MM/YYYY')}`}</b>
            
            <p className="mb-3">{content.content}</p>
            <p className="mb-3">Tệp đính kèm</p>
            {content.attachment && <a href={`${window.API_BASE_URL}${API_URLs.DOWNLOAD}/${content.attachment}`} target="_blank" download={content.attachment}>{content.attachment}</a>}

            {(expired_time - moment.utc().tz('Asia/Ho_Chi_Minh') < 0)?<p className="mb-3">Đã kết thúc bình bầu</p>:

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div>
                    <Card className="justify-content-between">
                        <h6 className="mb-3">Danh sách ủy quyền</h6>
                        <Radio.Group options={ds_ng_uyquyen} value={ng_uyquyen} />
                    </Card>
                    <br />
                </div>
                <p className="mb-3">{`Số CP cá nhân: ${phieubbCaNhan} CP`}</p>
                <p className="mb-3">{`Số CP ủy quyền: ${phieubbUyQuyen} CP`}</p>
                <p className="mb-3">{`Tổng số phiếu bình bầu: ${(phieubbUyQuyen + phieubbCaNhan)*ung_vien.length} phiếu`}</p>
                <p className="mb-3">{`Số phiếu còn lại bạn có thể bình bầu: ${phieu_chua_bb} phiếu`}</p>
                <p className="mb-3">{`Số phiếu bạn đã bình bầu: ${phieu_da_bb} phiếu`}</p>
                {(notstart)?<p className="mb-3">Chưa bắt đầu bình bầu</p>:<></>}
                {(isBB)?<p className="mb-3">Bạn đã bình bầu</p>:<div/>}
                <div>
                    <Card className="justify-content-between">
                        <h6 className="mb-3">Danh sách bình bầu</h6>
                        <Row gutter={[16, 24]}>
                            {ung_vien?.map((item, key) => {
                                return <Card className="gutter-row" span={6}
                                style={{
                                    width: 200,
                                    height:300
                                }}
                                    cover={<img alt="example" style={{ height: '150px', width: '70%', margin: 'auto' }} src={(item.avatar && item.avatar !== DEFAULT_AVATAR) ? `${window.API_BASE_URL}${API_URLs.DOWNLOAD}?file_name=${item.avatar}`: DEFAULT_AVATAR } />}
                                >
                                    <h6 style={{ textAlign: 'center', height:'35px' }} >{GENDER_TEXT[item.gender]} {item.name}</h6>
                                    <Form.Group controlId="ctrlName">
                                        <Form.Label>Số lượng phiếu bầu <span>*</span></Form.Label>
                                        <Form.Control type='number' min="0" onWheel={(e) => {
                                            e.currentTarget.blur()
                                        }}  required className='noscroll' defaultValue={0} value={item.sophieu} readOnly={isBB || notstart} onChange={(e) => {
                                            onChangePhieuBau(item.email, e.target.value)
                                        }} />
                                    <Form.Control.Feedback type="invalid">
                                        Số phiếu bầu không hợp lệ
                                    </Form.Control.Feedback>
                                    </Form.Group>

                                </Card>
                            })}
                        </Row>

                    </Card>
                    <br />
                </div>

                <div className="mt-3">
                    <Button disabled={isBB || notstart} type="submit" variant="primary">Bình bầu</Button>
                </div>

            </Form>}
        </Card>
    </div >
}
export default BinhBau;