import BaseService from "./baseService";
import { API_URLs } from '../constants/app';

class UploadService extends BaseService {
    async upload_event_image(event_id, formData) {
        const res = await this.post(`${API_URLs.UPLOAD}/${event_id}`, formData);
        return res;
    }

    async uploadAttachment(formData) {
        const res = await this.post(`${API_URLs.UPLOAD}`, formData);
        return res;
    }

    async upload_avatar(cccd, formData) {
        const res = await this.post(`${API_URLs.UPLOAD}/${cccd}`, formData);
        return res;
    }
    // async create(params) {
    //     const res = await this.post(API_URLs.BIEUQUYET_CONTENT, params);
    //     return res;
    // }

    // async get_list_bieuquyet(params) {
    //     const res = await this.get(API_URLs.BIEUQUYET_CONTENT, params);
    //     return res;
    // }
    
    // async get_bieuquyet(id){
    //     console.log(`${API_URLs.BIEUQUYET_CONTENT}/${id}`)
    //     const res = await this.get(`${API_URLs.BIEUQUYET_CONTENT}/${id}`);
    //     return res;
    // }

    // async update_bq(id, params){
    //     const res = await this.put(`${API_URLs.BIEUQUYET_CONTENT}/${id}`, params);
    //     return res;
    // }

    // async delete_bq(id) {
    //     const res = await this.delete(`${API_URLs.BIEUQUYET_CONTENT}/${id}`);
    //     return res;
    // }
    // async delete_event(id){
    //     const res = await this.delete(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async get_event_info(id){
    //     const res = await this.get(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async update_event(id, params){
    //     const res = await this.put(`${API_URLs.EVENT}/${id}`, params);
    //     return res;
    // }
}

export default UploadService;
