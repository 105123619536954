import { Card } from 'antd';
import './index.css';
import { useEffect, useState } from "react";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { MESSAGES } from '../../../constants/message';
import API from '../../../services/API';

const UyQuyen = (props) => {
    const [validated, setValidated] = useState(false);
    const [validUyquyen, setValidUyQuyen] = useState(true);
    const [email, setEmail] = useState('');
    const onChangeUyQuyen = props.onChange;
    useEffect(async () => {
        
        // console.log(info)
        console.log(props)
        if (props.email){
            console.log('XXXX')
            setValidUyQuyen(false)
            onChangeUyQuyen(false)
        }

    }, [props])

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }
        let params = { uyquyen: email, event: props.eventId };

       
        try {
            await API.vote_ticket.uyquyen(params);
            setValidUyQuyen(false)
            onChangeUyQuyen(false)
            toast.success('Thành công')
        } catch (error) {
            toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        }


        // const postData = {
        //     "name": name,
        //     "role": role,
        //     "phone": phone,
        //     "email": email,
        //     "avatar": avatar
        // }
        // try {
        //     await API.customer.update(currentUser.id, postData);
        //     toast.success(MESSAGES.UpdateSuccess);
        //     dispatch(getUserInfo());
        // } catch (error) {
        //     console.error(error);
        //     toast.error(error?.response?.data?.detail || MESSAGES.AnErrorOccurred);
        // }
    };

    return <div className="ne-page-body ne-page-customers">

        <Card className="mb-3">
            <h5 className="mb-3">Ủy quyền cho cá nhân bình bầu, biểu quyết</h5>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col md="6">
                        <Form.Group controlId="ctrlName">
                            <Form.Label>Email người được ủy quyền <span>*</span></Form.Label>
                            <Form.Control readOnly={!validUyquyen} placeholder='Email của người ủy quyền' required value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Email không hợp lệ
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {validUyquyen ? <p /> : <p className="mb-3">{`Bạn đã ủy quyền cho người có Email: ${props.email}`}</p>}
                <div className="mt-3">
                    <Button type="submit" disabled={!validUyquyen} variant="primary">{validUyquyen ? 'Ủy quyền' : 'Hủy ủy quyền'}</Button>
                </div>

            </Form>
        </Card>
    </div>
}
export default UyQuyen;