import BaseService from "./baseService";
import { API_URLs } from '../constants/app';

class BieuQuyetContentService extends BaseService {
    async create(params) {
        const res = await this.post(API_URLs.BIEUQUYET_CONTENT, params);
        return res;
    }

    async get_list_bieuquyet(params) {
        const res = await this.get(API_URLs.BIEUQUYET_CONTENT, params);
        return res;
    }
    
    async get_bieuquyet(id){
        console.log(`${API_URLs.BIEUQUYET_CONTENT}/${id}`)
        const res = await this.get(`${API_URLs.BIEUQUYET_CONTENT}/${id}`);
        return res;
    }

    async update_bq(id, params){
        const res = await this.put(`${API_URLs.BIEUQUYET_CONTENT}/${id}`, params);
        return res;
    }

    async delete_bq(id) {
        const res = await this.delete(`${API_URLs.BIEUQUYET_CONTENT}/${id}`);
        return res;
    }

    async export_bq(id) {
        const res = await this.export(`${API_URLs.BIEUQUYET_CONTENT}/${id}/export`)
        return res;
    }
    // async delete_event(id){
    //     const res = await this.delete(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async get_event_info(id){
    //     const res = await this.get(`${API_URLs.EVENT}/${id}`);
    //     return res;
    // }

    // async update_event(id, params){
    //     const res = await this.put(`${API_URLs.EVENT}/${id}`, params);
    //     return res;
    // }
}

export default BieuQuyetContentService;
