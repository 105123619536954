import React, { useEffect, useState } from 'react';
import MyHeader from "../components/MyHeader";
import MyFooter from "../components/MyFooter";
import MySidebar from "../components/MySidebar";
import { ToastContainer } from 'react-toastify';
import './AppLayout.css';

function AppLayout({ children }) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    setSidebarState();
    window.addEventListener('resize', setSidebarState)
    return (() => {
      window.removeEventListener('resize', setSidebarState)
    })
  }, [])

  const handleSidebarToggleClick = (e) => {
    const collapsed = !sidebarCollapsed;
    setSidebarCollapsed(collapsed);
  }

  const setSidebarState = () => {
    const winWidth = window.innerWidth;
    if (winWidth <= 992 && !sidebarCollapsed) {
      setSidebarCollapsed(true);
    }
  }

  const handleSidebarItemClick = () => {
    hideMobileSidebar();
  }

  const handleHeaderMenuClick = () => {
    hideMobileSidebar();
  }

  const hideMobileSidebar = () => {
    const winWidth = window.innerWidth;
    if (winWidth <= 992) {
      setSidebarCollapsed(true);
    }
  }

  return (
    <>
      <main className={`ne-main-z ${sidebarCollapsed ? 'ne-main-collapsed' : 'ne-main-expanded'}`}>
        <MySidebar collapsed={sidebarCollapsed} onSidebarItemClick={handleSidebarItemClick} onSidebarToggleClick={(e) => handleSidebarToggleClick(e)} />
        <div className="ne-sidebar-overlay" onClick={() => setSidebarCollapsed(true)}></div>
        <div className="ne-page-content">
          <MyHeader handleHeaderMenuClick={handleHeaderMenuClick} className={`${sidebarCollapsed ? 'ne-header-collapsed' : 'ne-header-collapsed'}`} onSidebarToggleClick={(e) => handleSidebarToggleClick(e)} />
          <div className="ne-page-content-wrap">
            {children}
          </div>
          <MyFooter />
        </div>
      </main>
      <ToastContainer />
    </>
  );
}

export default AppLayout;
